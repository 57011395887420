import {
  VATOptions as VATOptionsType,
  PromotionProperties as PromotionPropertiesType,
  ProductSKU as ProductSKUType,
  ShippingRuleProperties as ShippingRuleType,
} from "@deeple-ai/shopping-cart-calculator";

import { ProductOptionType, DigitalContentOptions } from "types/Product";
import { CustomerAddressType } from "types/Customer";

export type RecipientInformationFormData = {
  email: string;
  customerName?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isSentResponseMessage: boolean;
  note?: string;
};

export type CartProductOptionErrorIndexesType = {
  productSKUId: string;
  errorIndexes: number[];
};

export type SelectedProductSKUType = {
  amount: number;
  isAddedByAdmin?: boolean;
  isFree: boolean;
  options?: ProductOptionType[];
  productCode?: string;
  productId: string;
  productPrice: number;
  productSKUId: string;
  productSKUStock: number;
  reserved?: number;
  product: {
    id: string;
    type: string;
    weight?: number;
  };
};

export type PaySlipType = {
  totalPrice: number;
  discountTotalPrice: number;
  discountShippingPrice: number;
  grandTotal: number;
  vatOptions: VATOptionsType;
  vatPrice: number;
  vatValue: number;
  productPromotions: PromotionPropertiesType[];
  shippingPromotions: PromotionPropertiesType[];
};

export type ProductProperty = {
  id: string;
  type: string;
  weight?: number;
};

export type ItemType = {
  amount: number;
  isAddedByAdmin?: boolean;
  reservedAmount?: number;
  reserved?: number;
  reservedProductSKUId?: string;
  isDisabled?: boolean;
  isFree: boolean;
  note?: string;
  options?: ProductOptionType[];
  productCode?: string;
  productId: string;
  productPrice?: number;
  productSKUId: string;
  productSKUStock: number;
  rootProductPromotion?: string;
  shippingRules?: ShippingRuleType[];
  product: ProductProperty;
  // for digital content type
  digitalContentOptions?: DigitalContentOptions;
};

export type ChangeFieldType = {
  amount?: number;
  productPrice?: number;
  productSKUId?: string;
  isDisabled?: boolean;
  options?: ProductOptionType[];
  note?: string;
};

export type FreeProductType = {
  id: string;
  isFree: boolean;
  product: {
    id: string;
  };
  amount: number;
  stock: number;
};

export type ProductSKUInputsType = Partial<ProductSKUType & FreeProductType & ItemType>;

export enum CHECKOUT_STEP {
  SHIPPING_SELECTION = "SHIPPING_SELECTION",
  BRANCH_SELECTION = "BRANCH_SELECTION",
  ADDRESS = "ADDRESS",
  DELIVERY_METHOD = "DELIVERY_METHOD",
  FILL_RECIPIENT_INFO = "FILL_RECIPIENT_INFO",
}

export type OrderProductType = {
  productId: string;
  productSKUId: string;
  amount: number;
  isFree: boolean;
  isAddedByAdmin?: boolean;
  options: ProductOptionType[];
  note: string;
};

export type OfflineCustomerType = {
  id?: string;
  name: string;
  address: CustomerAddressType;
};
