import { useQuery } from "@apollo/client";
import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import get from "lodash/get";
import flatten from "lodash/flatten";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType, components, OptionProps, SingleValueProps } from "react-select";
import { Components } from "@deeple-ai/web-console";

import Card from "components/Card";
import Grid from "components/Grid";
import Modal from "components/Modal";
import SelectWithAsyncPaginate from "components/SelectWithAsyncPaginate";
import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";

import SelectedProductManagement from "domain/Promotion/PromotionProduct/SelectedProductManagement";
import useSelectedProduct from "domain/Promotion/AddEditPromotionModal/hook/useSelectedProducts";
import useManageSelectedOptions from "domain/Product/ProductListWithSearch/ProductSelectorContainer/hook/useManageSelectedOptions";
import groupProductByCategory from "domain/Product/ProductListWithSearch/ProductSelectorContainer/groupProductByCategory";
import Popup from "domain/Product/ProductListWithSearch/ProductSelector/Popup";
import mergeSelectedOptions from "domain/Product/ProductListWithSearch/ProductSelectorContainer/mergeSelectedOptions";
import getValuesFromOptions from "domain/Product/ProductListWithSearch/ProductSelectorContainer/getValuesFromOptions";
import CategoriesAndProductsTotal from "domain/Promotion/CategoriesAndProductsTotal";

import { MAXIMUM_BROADCAST_NAME_STRING_LENGTH, DEFAULT_SORT_PRODUCT_BY } from "config";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { DATE_FORMAT } from "constants/DateTimeFormat";
import { FACEBOOK_DEFAULT_LINK } from "constants/Facebook";
import { PRODUCTS } from "graphql/product/query";
// import { AddCommentCrawlerInput } from "types/FacebookCrawler";
import { FacebookLiveInput, FacebookPost, LiveStatusType } from "types/FacebookLive";
import { GetProductsQueryType, NewProductItemProps, FacebookLiveProductSKUType } from "types/Product";
import { PromotionProductSKUType } from "types/Promotion";
import { ProjectIdType } from "types/Project";
import useToggle from "utils/hooks/useToggle";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useTotalWarehouse from "utils/hooks/Product/useTotalWarehouse";

import FacebookMessageCard from "domain/MarketingTools/FacebookAutoEngagement/FacebookMessageCard";
import useStyles from "./useStyles";
import ProductSelectorContainer from "./ProductCodeSelector";
import useFetchFacebookPost from "../FacebookLiveFormContainer/hooks/useFetchFacebookPost";
import useFetchFacebookPostByPostId from "../FacebookLiveFormContainer/hooks/useFetchFacebookPostByPostId";
import useFetchFacebookPage from "../FacebookLiveFormContainer/hooks/useFetchFacebookPage";
import { IframeWrapper } from "../styled";

type FacebookCampaignFormPropsType = {
  projectId: string;
  facebookCrawlerData: FacebookLiveInput;
  setFacebookCrawlerData: Function;
  disabledForm: boolean;
  isNewCreateForm: boolean;
  facebookPageId: string;
  facebookLiveSelectedProductSKUs: FacebookLiveProductSKUType[];
  onClickAlert: () => void;
};

const FacebookLiveAddEditForm: FC<FacebookCampaignFormPropsType> = ({
  facebookLiveSelectedProductSKUs,
  facebookCrawlerData,
  setFacebookCrawlerData,
  facebookPageId,
  disabledForm,
  isNewCreateForm,
  onClickAlert,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { projectId } = useParams<ProjectIdType>();
  const { isOpen, handleClose, handleToggle } = useToggle();
  const isDesktop = useIsDesktop();
  const [searchText, setSearchText] = useState("");

  const { postsData, isPostsDataLoading } = useFetchFacebookPost(projectId, 100, "");
  const { postPreviewData, isPostPreviewDataLoading } = useFetchFacebookPostByPostId(
    projectId,
    facebookCrawlerData.postId,
  );
  const { facebookPageData, isFacebookPageDataLoading } = useFetchFacebookPage(projectId);

  const onChangeSearch = useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
  }, []);

  const { selectedOptions, onSelect, onDeleteTag, onClearAllTag, setSelectedOptions } = useManageSelectedOptions();
  const { data, loading } = useQuery<GetProductsQueryType>(PRODUCTS, {
    variables: {
      projectId,
      filter: {
        productName: searchText,
        sortBy: DEFAULT_SORT_PRODUCT_BY,
        isCfCode: true,
        isFree: false,
      },
    },
    fetchPolicy: "network-only",
  });

  const products = data?.products?.results || [];

  const {
    selectedSelectedProducts,
    deleteSelectedProduct,
    selectProducts,
    convertSelectedProducts,
  } = useSelectedProduct();

  const { isLoading, totalCategory, totalProduct, totalProductSKU } = useTotalWarehouse(projectId);

  const { groupProducts, newSelectedOptions } = useMemo(() => {
    const filteredProductWithCfCode = (products.filter((product) => {
      const { cfCode, productSKUs } = product;

      const removedProductFromList = !cfCode && !productSKUs.length;

      return !removedProductFromList;
    }) as unknown) as NewProductItemProps[];

    return groupProductByCategory(t, filteredProductWithCfCode, selectedSelectedProducts);
  }, [products, selectedSelectedProducts, t]);

  useEffect(() => {
    if (isOpen && newSelectedOptions) setSelectedOptions(newSelectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!Array.isArray(facebookLiveSelectedProductSKUs)) return;
    const productSKUs = facebookLiveSelectedProductSKUs.map((productSKU) => {
      return {
        ...productSKU,
        image: productSKU.image.src,
      };
    });

    convertSelectedProducts(((productSKUs as unknown) as PromotionProductSKUType[]) || []);
  }, [facebookLiveSelectedProductSKUs, convertSelectedProducts]);

  useEffect(() => {
    const productSKUIds = selectedSelectedProducts.map(({ productSKUs }: NewProductItemProps) => {
      const selectedProductSKUIds = productSKUs.map(({ id }) => id);
      return selectedProductSKUIds;
    });

    const newFacebookCrawlerData = {
      ...facebookCrawlerData,
      productSKUIds: flatten(productSKUIds),
    };

    setFacebookCrawlerData(newFacebookCrawlerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSelectedProducts]);

  const mergedOptions = useMemo(() => mergeSelectedOptions(groupProducts, selectedOptions), [
    groupProducts,
    selectedOptions,
  ]);

  const [inputValue, onInputChangeRaw] = useState("");
  const FacebookPostId = facebookCrawlerData?.postId || "";
  const embedLink = `${FACEBOOK_DEFAULT_LINK}?story_fbid=${FacebookPostId}&id=${facebookPageId}`;

  const onInputChange = (newInputValue: string) => {
    onInputChangeRaw(newInputValue);
  };

  const onSelectChange = (newInputValue: ValueType<OptionType>) => {
    setFacebookCrawlerData({
      ...facebookCrawlerData,
      postId: get(newInputValue, "value"),
      videoId: get(newInputValue, "videoId"),
    });
  };

  const handleChangeTitle = (newTitle: string) => {
    if (newTitle.length <= MAXIMUM_BROADCAST_NAME_STRING_LENGTH) {
      setFacebookCrawlerData({
        ...facebookCrawlerData,
        title: newTitle,
      });
    }
  };

  const handleToggleSwitch = () => {
    setFacebookCrawlerData({
      ...facebookCrawlerData,
      isActive: !facebookCrawlerData?.isActive,
    });
  };

  const handleSetIsIncludedAllProducts = (isIncludedAllProducts: boolean) => {
    setFacebookCrawlerData({
      ...facebookCrawlerData,
      isIncludedAllProducts,
    });
  };

  const SingleValue = (
    optionProps: SingleValueProps<{ label: string; image: string; value: string; createdTime: string }>,
  ) => {
    const { data } = optionProps;
    return (
      <components.SingleValue {...optionProps}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item className="flex-0">
            <Grid container alignContent="center" alignItems="center">
              <img src={data.image} width="32" height="32" alt={data.image} className="mr-2" />
            </Grid>
          </Grid>
          <Grid item className="hiddenOverFlowText flex-1">
            <Grid container alignContent="center" alignItems="center">
              <Typography variant="title8" color="inherit" className="hiddenOverFlowText">
                {data.label}
              </Typography>
            </Grid>

            <Grid container alignContent="center" alignItems="center" justify="space-between">
              <Typography variant="body4" color="inherit">
                {`${t("facebookLiveForm.postId")}`} {data.value}
              </Typography>

              <Typography variant="body4" color="inherit" className="pr-2">
                {data.createdTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </components.SingleValue>
    );
  };

  const Option = (optionProps: OptionProps<{ label: string; image: string; value: string; createdTime: string }>) => {
    const { data } = optionProps;
    return (
      <components.Option {...optionProps}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item className="flex-0">
            <Grid container alignContent="center" alignItems="center">
              <img src={data.image} width="32" height="32" alt={data.image} className="mr-2" />
            </Grid>
          </Grid>
          <Grid item className="hiddenOverFlowText flex-1">
            <Grid container alignContent="center" alignItems="center">
              <Typography variant="title8" color="inherit" className="hiddenOverFlowText">
                {data.label}
              </Typography>
            </Grid>

            <Grid container alignContent="center" alignItems="center" justify="space-between">
              <Typography variant="body4" color="inherit">
                {`${t("facebookLiveForm.postId")}`} {data.value}
              </Typography>

              <Typography variant="body4" color="inherit" className="pr-2">
                {data.createdTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </components.Option>
    );
  };

  const formatFacebookPostOption = (facebookPostList: FacebookPost[]) => {
    return facebookPostList.map((post: FacebookPost) => {
      return {
        image: get(post, "attachments.data[0].media.image.src") || NO_IMG_AVAILABLE,
        label: post.message ? `${post.message}` : "No caption",
        createdTime: post.createdTime && moment(post.createdTime).format(DATE_FORMAT),
        value: post.id,
        videoId: post.videoId,
      };
    });
  };

  const loadOptions = async (search: string) => {
    const postsDataList: FacebookPost[] = get(postsData, "posts.data") || [];
    const optionsPublic = formatFacebookPostOption(postsDataList);

    let filteredOptions;
    if (!search) {
      filteredOptions = optionsPublic;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = optionsPublic.filter(
        (option: OptionType) =>
          option.value.toLowerCase().includes(searchLower) || option.label.toLowerCase().includes(searchLower),
      );
    }

    let hasMore = false;
    // TODO: need to fix this to support fetch more data pagination
    const afterValue = get(postsData, "posts.paging.next");
    if (afterValue) {
      hasMore = true;
    }

    return {
      options: filteredOptions,
      hasMore,
    };
  };

  const handleSubmitProducts = useCallback(() => {
    const values = getValuesFromOptions(selectedOptions);

    selectProducts(values);
    onClearAllTag();
    handleClose();
  }, [handleClose, onClearAllTag, selectProducts, selectedOptions]);

  const isDisabledClearAll = !selectedOptions.length;

  const isLiveStatusEnded = facebookCrawlerData?.liveStatus === LiveStatusType.END || false;

  return (
    <>
      <Grid item xs={12}>
        <div className="web-console mb-3">
          <Components.FacebookLiveAlert onClick={onClickAlert} />
        </div>

        <Card className="mb-3" noShadow>
          <Grid container>
            <Grid item xs={12} className="py-1">
              <Grid container justify="space-between" alignContent="center" alignItems="center" className="pb-2">
                <Typography variant="title8" color="dark">
                  {`${t("facebookLiveForm.title")}`}
                </Typography>
                <Switch checked={facebookCrawlerData?.isActive} onChange={handleToggleSwitch} />
              </Grid>

              <TextField
                variant="outlined"
                className="py-1"
                placeholder={`${t("facebookLiveForm.title")}`}
                fullWidth
                value={facebookCrawlerData?.title || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e.target.value)}
                disabled={!isNewCreateForm}
              />
            </Grid>
          </Grid>
        </Card>
        <Card className="mb-3" noShadow>
          <Grid container>
            <Grid item xs={12} className="py-1">
              <Typography variant="title8" color="dark">
                {`${t("facebookLiveForm.post")} `}
                {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={embedLink} target="_blank">
                  {FacebookPostId}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} className="py-1">
              <Card noShadow>
                {!disabledForm && !isPostsDataLoading && (
                  <SelectWithAsyncPaginate
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    className="mb-2"
                    loadOptions={loadOptions}
                    onChange={onSelectChange}
                    components={{ SingleValue, Option }}
                    placeholder={`${t("facebookLiveForm.postId")}`}
                  />
                )}
                {isPostPreviewDataLoading || isFacebookPageDataLoading ? (
                  <IframeWrapper>
                    <FacebookMessageCard
                      pageName="..."
                      pagePicture=""
                      loading={isPostPreviewDataLoading}
                      message=""
                      image=""
                      createdTime=""
                    />
                  </IframeWrapper>
                ) : (
                  <>
                    {postPreviewData && (
                      <IframeWrapper>
                        <FacebookMessageCard
                          pageName={get(facebookPageData, "page.name")}
                          pagePicture={get(facebookPageData, "page.picture")}
                          loading={isPostPreviewDataLoading}
                          message={get(postPreviewData, "post.message")}
                          image={get(postPreviewData, "post.attachments.data[0].media.image.src")}
                          createdTime={
                            postPreviewData &&
                            postPreviewData.post &&
                            postPreviewData.post.createdTime &&
                            moment(postPreviewData.post.createdTime).format(DATE_FORMAT)
                          }
                        />
                      </IframeWrapper>
                    )}
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </Card>

        <Card noShadow>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="title8" color="dark">
                {`${t("facebookLiveForm.cfCode.title")}`}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-2">
              <Typography variant="body4" color="darkMed">
                {`${t("facebookLiveForm.cfCode.description")}`}
              </Typography>
            </Grid>

            <Grid item xs={12} className="pt-2">
              <ProductSelectorContainer
                isDisabled={(!isNewCreateForm && !facebookCrawlerData?.isActive) || isLiveStatusEnded}
                onClickBrowse={handleToggle}
                placeholder={t("facebookLiveForm.productSelector.placeholder")}
                isIncludedAllProducts={facebookCrawlerData.isIncludedAllProducts}
                handleSetIsIncludedAllProducts={handleSetIsIncludedAllProducts}
              />
            </Grid>
            {/* Show when have selected product */}
            {!facebookCrawlerData.isIncludedAllProducts ? (
              <Grid item xs={12}>
                <SelectedProductManagement
                  isDisabled={(!isNewCreateForm && !facebookCrawlerData?.isActive) || isLiveStatusEnded}
                  products={selectedSelectedProducts}
                  onDelete={deleteSelectedProduct}
                />
              </Grid>
            ) : (
              <CategoriesAndProductsTotal
                isLoading={isLoading}
                categoriesTotal={totalCategory}
                productsTotal={totalProduct}
                productSKUsTotal={totalProductSKU}
              />
            )}
          </Grid>
        </Card>
      </Grid>

      <Modal
        maxWidth="md"
        classes={classes}
        title={t("PRODUCT_SELECTOR")}
        fullScreen={!isDesktop}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Popup
          isDisabledClearAll={isDisabledClearAll}
          isLoading={loading}
          onChangeSearch={onChangeSearch}
          onClearAllTag={onClearAllTag}
          onDeleteTag={onDeleteTag}
          onSelect={onSelect}
          onSubmit={handleSubmitProducts}
          options={mergedOptions}
          placeholder={t("facebookLiveForm.productSelector.placeholder")}
          selectedOptions={selectedOptions}
        />
      </Modal>
    </>
  );
};

export default FacebookLiveAddEditForm;
