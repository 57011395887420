const newQuickStart = {
  en: {
    GET_STARTED: "Your store is ready",
    LEARN_TO_USE_DEEPLE: "Get to know more about deeple and adjust settings for your store as the guideline below.",
    LEARN_MORE: "Learn more",
    ADD_CATALOGS_AND_PRODUCT: "Add your catalogs and products",
    ADD_DETAILS_TO_YOUR_STORE:
      "Add catalogs, products and product details to your store and fill the inventory, making the bot ready to sell your products.",
    ADD_YOUR_SHIPPING_METHODS: "Add your shipping",
    SELECT_SHIPPING_METHOD:
      "Select a shipping method, such as Thailand Post, Kerry, FLASH, LALAMOVE, or SHIPPOP, and set shipping cost and rate for each method.",
    CONNECT_YOUR_FACEBOOK_OR_LINE: "Connect your Facebook page or LINE OA to deeple AI chatbot",
    CONNECT_DEEPLE_TO_YOUR_STORE:
      "Connect deeple to your store's Facebook Page, Instagram Business account and LINE OA, allowing us to help manage your store.",
    ADD_PRODUCT: "Add product",
    ADD_SHIPPING: "Add your shipping",
    AI_SETTINGS: "Set up",
    CONNECT: "Connect",
    GO_TO_PRODUCT: "Go to product",
    GO_TO_SHIPPING: "Go to shipping",
    GO_TO_CONNECT: "Go to connect",
    GO_TO_AI_SETTING: "Go to AI settings",
    WATCH_A_TUTORIAL: "Watch a tutorial",
    COMPLETED: "Completed",
    STEP_TO_GO: "1 step to go",
    STEPS_TO_GO: "{{step}} steps to go",
    TRAIN_YOUR_BOT: "Set up response mode and AI customization",
    ENHANCE_YOUR_BOT_TO_NEXT_LEVEL:
      "Select response mode, customize Welcome Message, train bot to answer Specialized Knowledge, and adjust other AI settings.",
    PAYMENT_DEEPLE_PAY_TITLE: "Set up your payment channel",
    PAYMENT_DEEPLE_PAY_DESCRIPTION:
      "Select your preferred payment channel between deeple pay and bank transfer and set up payment information for your store.",
    PAYMENT_DEEPLE_PAY_BUTTON: "Set up payment",
    PAYMENT_DEEPLE_PAY_SUCCESS_BUTTON: "Go to payment gateway",
    QUICK_START_VIDEO_TITLE: "How can deeple help manage your store through Social Commerce?",
  },
  th: {
    GET_STARTED: "ร้านค้าของคุณพร้อมใช้งานแล้ว",
    LEARN_TO_USE_DEEPLE: "เรียนรู้และตั้งค่าการใช้งาน deeple ด้านอื่น ๆ ให้ร้านค้าของคุณ ตามคำแนะนำด้านล่าง",
    LEARN_MORE: "เรียนรู้เพิ่มเติม",
    ADD_CATALOGS_AND_PRODUCT: "เพิ่มเมนูสินค้าและสินค้า",
    ADD_DETAILS_TO_YOUR_STORE:
      "เพิ่มหมวดหมู่สินค้า รายการสินค้า และกำหนดจำนวนสินค้าในคลังสินค้า เพื่อเตรียมพร้อมให้แชทบอท เสนอขายสินค้าในร้านของคุณ",
    ADD_YOUR_SHIPPING_METHODS: "เพิ่มช่องทางการจัดส่ง",
    SELECT_SHIPPING_METHOD:
      "เลือกช่องทางการจัดส่งสินค้า เช่น ไปรษณีย์ไทย, Kerry, FLASH, LALAMOVE, SHIPPOP รวมถึงระบุค่าจัดส่ง และวิธีคำนวณค่าจัดส่ง",
    CONNECT_YOUR_FACEBOOK_OR_LINE: "เชื่อมต่อ Facebook page, บัญชี Instagram Business หรือ LINE OA",
    CONNECT_DEEPLE_TO_YOUR_STORE:
      "เชื่อมต่อ deeple กับ Facebook Page บัญชี Instagram Business หรือ LINE OA ของร้านค้า ให้เราช่วยจัดการร้านค้าของคุณ",
    ADD_PRODUCT: "เพิ่มสินค้า",
    ADD_SHIPPING: "เพิ่มช่องทางจัดส่ง",
    AI_SETTINGS: "ตั้งค่า",
    CONNECT: "เชื่อมต่อ",
    GO_TO_PRODUCT: "ไปหน้าสินค้า",
    GO_TO_SHIPPING: "ไปหน้าช่องทางการจัดส่ง",
    GO_TO_CONNECT: "ไปหน้าเชื่อมต่อร้านค้า",
    GO_TO_AI_SETTING: "ไปหน้าสอนบอท",
    WATCH_A_TUTORIAL: "ดูวิธีการตั้งค่า",
    COMPLETED: "เสร็จสิ้น",
    STEP_TO_GO: "เหลืออีก 1 ขั้นตอน",
    STEPS_TO_GO: "เหลืออีก {{step}} ขั้นตอน",
    TRAIN_YOUR_BOT: "ตั้งค่าโหมดการตอบแชทและการใช้งาน AI",
    ENHANCE_YOUR_BOT_TO_NEXT_LEVEL:
      "เลือกโหมดการตอบแชทที่ต้องการ และยกระดับร้านค้าของคุณไปอีกขั้นด้วยการปรับแต่งข้อความต้อนรับ สอนบอท ตอบคำถามเฉพาะทาง คำถามที่พบบ่อย รวมถึงการตั้งค่าการใช้งาน AI ด้านอื่น ๆ",
    PAYMENT_DEEPLE_PAY_TITLE: "เลือกช่องทางการชำระเงิน",
    PAYMENT_DEEPLE_PAY_DESCRIPTION:
      "เลือกช่องทางการชำระเงินที่ต้องการระหว่าง deeple pay และการโอนเงินผ่านธนาคาร และตั้งค่าข้อมูลการชำระเงินสำหรับร้านค้าของคุณ",
    PAYMENT_DEEPLE_PAY_BUTTON: "ตั้งค่าช่องทางการชำระเงิน",
    PAYMENT_DEEPLE_PAY_SUCCESS_BUTTON: "ไปหน้าช่องทางการชำระเงิน",
    QUICK_START_VIDEO_TITLE: "deeple ช่วยคุณจัดการร้านค้าผ่าน Social Commerce ได้อย่างไร",
  },
};

export default newQuickStart;
