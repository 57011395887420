import { Utilities } from "@deeple-ai/web-console";
import { changeLanguage as deepleUIChangeLanguage } from "@deeple-ai/deeple-ui";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import get from "lodash/get";

import { SUPPORTED_LANGUAGE } from "constants/Language";
import { translate } from "./translations";

/**
 * Change client language
 * - local storage
 * - i18n on sme-console components and pages
 * - i18n on web-console components and pages
 * @param language
 */
export const changeLanguage = (language: string): void => {
  // Updating local storage
  localStorage.setItem("i18nextLng", language.toLowerCase());
  // Updating i18n in sme-console components
  i18n.changeLanguage(language.toLowerCase());
  // Update i18n in web-console components
  Utilities.changeLanguage(language.toLowerCase());
  // Update i18n in deeple-ui components
  deepleUIChangeLanguage(language.toLowerCase());
};

/**
 * Get default language from either localStorage or default
 * language which has been hard-coded in the system "th"
 * @returns {string}
 */
const getDefaultLanguage = (): string => {
  const i18nLng = localStorage.getItem("i18nextLng");
  if (i18nLng) {
    return i18nLng;
  }

  // Setting default to "th"
  localStorage.setItem("i18nextLng", SUPPORTED_LANGUAGE.TH);

  return SUPPORTED_LANGUAGE.TH;
};

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === "development", // This should be open only development environment
  defaultNS: "translations", // have a common namespace used around the full app
  fallbackLng: [SUPPORTED_LANGUAGE.TH, SUPPORTED_LANGUAGE.EN],
  fallbackNS: "translations",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false, // we do not use keys in form messages.welcome
  lng: getDefaultLanguage(), // Init default language
  ns: ["translations", "REGISTER", "PLACE_ORDER_TO_LALAMOVE", "SEND_MESSAGE"],
  // we init with resources
  resources: translate,
});

/**
 * Get client's current language
 * @returns {string} language
 */
export const getCurrentLanguage = (): string => {
  return get(i18n, "languages.0") || SUPPORTED_LANGUAGE.TH;
};

export default i18n;
