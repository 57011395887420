import React from "react";
import { Components } from "@deeple-ai/web-console";
import { useTranslation } from "react-i18next";

import COLORS from "constants/Colors";
import Typography from "components/Typography";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { ModalAdditionalMessageExampleContent, ModalAdditionalMessageExampleImg, StyledButton } from "./styled";

interface ModalAdditionalMessageExampleProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalAdditionalMessageExample({
  isOpen,
  onClose,
}: ModalAdditionalMessageExampleProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const isDesktop = useIsDesktop();

  const imgSrc = isDesktop
    ? `/assets/img/additional-message-example-${i18n.language}.png`
    : `/assets/img/additional-message-example-${i18n.language}-mobile.png`;

  return (
    <Components.Modal isOpen={isOpen} onClose={onClose} size="medium" isClickOverlayToClose>
      <ModalAdditionalMessageExampleContent>
        <Typography variant="body3" className="text-center mb-2 mb-lg-4" color={COLORS.Gray500}>
          {t("broadcast.facebook.modal.additional.title")}
        </Typography>
        <div className="d-flex mx-auto w-auto mb-4 mb-lg-5">
          <ModalAdditionalMessageExampleImg src={imgSrc} alt="Additional Message Example" className="mx-auto" />
        </div>
        <StyledButton type="button" fullWidth onClick={onClose}>
          {t("Close")}
        </StyledButton>
      </ModalAdditionalMessageExampleContent>
    </Components.Modal>
  );
}
