const newRegister = {
  en: {
    SIGN_UP: "Sign up to deeple",
    SIGN_UP_APPLE: "Sign up with Apple",
    SIGN_UP_EMAIL: "Sign up with Email",
    SIGN_UP_GOOGLE: "Sign up with Google",
    SIGN_UP_FACEBOOK: "Sign up with Facebook",
    LOG_IN_HERE: "Log in here",
    OR: "OR",
    HAVE_ACCOUNT: "Already have an accout",
  },
  th: {
    SIGN_UP: "สร้างบัญชีใหม่",
    SIGN_UP_APPLE: "สร้างบัญชีใหม่ด้วย Apple",
    SIGN_UP_EMAIL: "สร้างบัญชีใหม่ด้วยอีเมล",
    SIGN_UP_GOOGLE: "สร้างบัญชีใหม่ด้วย Google",
    SIGN_UP_FACEBOOK: "สร้างบัญชีใหม่ด้วย Facebook",
    LOG_IN_HERE: "เข้าสู่ระบบที่นี่",
    OR: "หรือ",
    HAVE_ACCOUNT: "คุณมีบัญชีผู้ใช้แล้วใช่ไหม",
  },
};

export default newRegister;
