const planUsage = {
  en: {
    "planUsage.menu": "Package & usage",
    "planUsage.addNew.teamMember": "Invite new member",
    "planUsage.button.manage": "Manage",
    "planUsage.description.moreIntegration": "Need to integrate more platforms?",
    "planUsage.description.moreMember": "Do you need to invite more members?",
    "planUsage.description.noIntegration":
      "You can integrating your inventory with another platform (Lazada, Shopee, JD)",
    "planUsage.header": "Package & usage",
    "planUsage.label.usagePeriod": "Usage period : ",
    "planUsage.label.buyIntegrationPrice": "Buy only ฿ 450/mo",
    "planUsage.label.buyMore": "Buy more",
    "planUsage.label.buyMoreSeat": "Buy more seats",
    "planUsage.label.noIntegration": "You have no inventory integration",
    "planUsage.label.unit.subscriber": "customers",
    "planUsage.label.unit.intent": "intents",
    "planUsage.label.unit.message": "messages",
    "planUsage.label.unit.order": "orders",
    "planUsage.label.unit.sku": "Products",
    "planUsage.label.unit.broadcast": "messages",
    "planUsage.label.unit.lineMessage": "messages",
    "planUsage.label.unit.manualApproveOrder": "orders",
    "planUsage.label.unlimited": "Unlimited",
    "planUsage.label.upgrade": "Upgrade",
    "planUsage.label.yourPlan.imageSearchAddon": "Your image search package :",
    "planUsage.label.yourPlan.cCommercePackage": "Your order package :",
    "planUsage.label.yourPlan.mainPackage": "Your Package :",
    "planUsage.label.yourPlan.lineMessage": "Your LINE message quota",
    "planUsage.label.yourPlan.manualApproveOrder": "Your order",
    "packageLevel.free": "Free package",
    "packageLevel.starter_1": "Starter-S package",
    "packageLevel.starter_2": "Starter-M package",
    "packageLevel.starter_3": "Starter-L package",
    "packageLevel.standard": "Basic package",
    "packageLevel.premium": "Advance package",
    "packageLevel.professional": "Standard package",
    "planUsage.label.name.mainPackage":
      "{{ level }}{{ subscriptionType }} ({{subscriberQuota}} customers, {{orderQuota}} orders)",
    "planUsage.label.name.newPackage.mainPackage":
      "{{ level }}{{ subscriptionType }} ({{subscriberQuota}} customers, {{orderQuota}} products)",
    "planUsage.title.label.imageSearch": "Image search",
    "planUsage.label.unit.imageSearch": "Image",
    "planUsage.label.yourPlan": "Your Package :",
    "planUsage.limit.conjunction": "of",
    "planUsage.suffix.percentage": "% of Total",
    "planUsage.title.channel": "Channel",
    "planUsage.title.integration": "Inventory integration",
    "planUsage.title.label.cCommercePackage": "Order package",
    "planUsage.title.label.mainPackage": "Main package",
    "planUsage.title.label.subscriber": "Customers",
    "planUsage.title.label.intent": "AI Specialized Knowledge Intents",
    "planUsage.title.label.message": "Incoming Chat Messages",
    "planUsage.title.label.order": "Orders",
    "planUsage.title.label.sku": "Products",
    "planUsage.title.label.broadcast": "Broadcast messages",
    "planUsage.title.label.lineMessage": "Push Message",
    "planUsage.title.label.manualApproveOrder": "Manual Approve Order",
    "planUsage.title.teamMember": "Team member",
    "planUsage.tooltip.contactSale": "Please contact at sale@deeple.ai to upgrade",
    "planUpgrade.header": "Upgrade Package",
    "planUpgrade.heading.title": "Flexible pricing Packages",
    "planUpgrade.heading.subTitle": "that scales with your business",
    "planUpgrade.label.recommended": "Recommended",
    "planUpgrade.card.perMonth": "per month",
    "planUpgrade.card.detail.description": "Up to {{subscriber}} customers and {{order}} orders per month",
    "planUpgrade.card.detail.description.prefix": "Up to ",
    "planUpgrade.card.detail.description.subscriber": "{{subscriber}} customers",
    "planUpgrade.card.detail.description.and": " and ",
    "planUpgrade.card.detail.description.order": "{{order}} orders",
    "planUpgrade.card.detail.description.perMonth": " per month",
    "planUpgrade.card.detail.overCharge": "Over charge cost",
    "planUpgrade.label.oneTimeTrial": "one time trial",
    "planUpgrade.detail.label.order": "orders",
    "planUpgrade.detail.label.message": "Chat Messages",
    "planUpgrade.detail.label.intent": "AI Specialized Knowledge Intents",
    "planUpgrade.detail.label.sku": "products",
    "planUpgrade.detail.label.member": "team members",
    "planUpgrade.detail.label.channel": "Channel (Line or Facebook)",
    "planUpgrade.detail.label.channel_plural": "channels (Line and Facebook integration)",
    "planUpgrade.detail.label.saleReport": "Sale Report",
    "planUpgrade.detail.label.incomingMsg": "incoming chat messages",
    "planUpgrade.detail.label.imageSearch": "AI Visual Product Search",
    "planUpgrade.detail.label.subscriber": "customers",
    "planUpgrade.detail.label.subscriber.prefix": "",
    "planUpgrade.button.label.subscribePackages": "Subscribe packages",
    "planUpgrade.button.label.choose": "Choose",
    "planUpgrade.button.label.contactUs": "Contact us",
    "planUpgrade.button.label.freeTrial": "Free trial",
    "planUpgrade.button.label.currentPlan": "Current Package",
    "planUpgrade.detail.tooltip.extra": "Extra {{unit}} will be charged ฿{{extraPrice}}/{{unit}}",
    "planUpgrade.title.basicPackage": "Basic package",

    "planUpgrade.card.newPlan.free": "Free package",
    "planUpgrade.card.newPlan.free.description": "Best for small-sized businesses",
    "planUpgrade.card.newPlan.standard": "Basic package",
    "planUpgrade.card.newPlan.standard.description": "Best for small-sized businesses",
    "planUpgrade.card.newPlan.professional": "Standard package",
    "planUpgrade.card.newPlan.professional.description": "Best for small to medium-sized businesses",
    "planUpgrade.card.newPlan.premium": "Advanced package",
    "planUpgrade.card.newPlan.premium.description": "Best for medium-large sized businesses",
    "planUpgrade.card.newPlan.enterprise": "Custom package",
    "planUpgrade.card.newPlan.enterprise.description": "Best for enterprises",
    "planUpgrade.card.newPlan.enterprise.title": "Let’s talk",
    "planUpgrade.card.newPlan.detail.customer.prefix": "Up to",
    "planUpgrade.card.newPlan.detail.customer.suffix": "customers",
    "planUpgrade.card.newPlan.detail.orders": "orders",
    "planUpgrade.card.newPlan.detail.deeplePay.prefix": "Direct payments ",
    "planUpgrade.card.newPlan.detail.deeplePay.suffix": "via deeple pay",
    "planUpgrade.card.newPlan.detail.products.prefix": "",
    "planUpgrade.card.newPlan.detail.products.suffix": "products",
    "planUpgrade.card.newPlan.detail.ai": "AI Specialized Knowledge Intents",
    "planUpgrade.card.newPlan.detail.broadcast": "AI broadcast messages",
    "planUpgrade.card.newPlan.detail.openApi.prefix": "Open API",
    "planUpgrade.card.newPlan.detail.openApi.suffix": ": Standard",
    "planUpgrade.detail.value.deeplePay": "Direct payments",
    "planUpgrade.detail.label.deeplePay": "via deeple pay",
    "planUpgrade.label.fee": "{{value}}% fee",

    "planUpgrade.title.mainPackage": "Main package",
    "planUpgrade.title.cCommercePackage": "C-Commerce package",
    "planUpgrade.card.title.plan.free": "Free Package",
    "planUpgrade.card.title.plan.standard": "Basic Package",
    "planUpgrade.card.title.plan.premium": "Advanced Package",
    "planUpgrade.card.title.plan.professional": "Standard Package",
    "planUpgrade.card.title.plan.enterprise": "Custom Package",
    "planUpgrade.title.imageSearchPackage": "AI Visual product search",
    "planUpgrade.label.contactUs": "Contact us",
    "planUpgrade.label.contactSaleDetail": `For larger size package and advanced performance`,
    "planUpgrade.detail.tooltip.contactSale": "Please contact at sale@deeple.ai",
    "planUpgrade.label.prefix.over": "Over",
    "planUpgrade.label.suffix.imageSearch": "Image searches",
    "planUpgrade.label.suffix.order": "Orders",
    "planUpgrade.label.suffix.subscriber": "{{amount}} Customers",
    "planUpgrade.quota.unlimited": "Unlimited",
    "planUpgrade.subTitle.mainPackage": "{{ markLabel }} customers",
    "planUpgrade.subTitle.cCommercePackage": "{{ markLabel }} orders",
    "planUpgrade.card.label.mainPackage": "Your customers",
    "planUpgrade.card.label.cCommercePackage": "Your orders per month",
    "planUpgrade.card.slider.label.mainPackage": "{{ selectedValue }} customers",
    "planUpgrade.card.slider.label.cCommercePackage": "{{ selectedValue }} orders per month",
    "planUpgrade.card.title.main": "Main package",
    "planUpgrade.card.title.cCommerce": "C-Commerce package",
    "planUpgrade.card.title.imageSearch": "AI Visual product search",
    "upgradePlan.error.failed": "Upgrade failed!!, {{error}}",
    "upgradePlan.error.unSuccessfulDownGrade":
      "Unable to downgrade package. Please make sure that AI Specialized Knowledge, SKUs, Team members, Connect Channel(LINE, Facebook), and Add-on does not exceed the package you want to downgrade",
    "planUpgrade.card.description.free": "Try for free",
    "planUpgrade.card.description.estimateCost": "Estimated cost",
    "planUpgrade.card.description.startAt": "Starts at",
    "planUpgrade.card.description.enterprise": "For more info, please",
    "planUpgrade.detail.label.liveChatAgent": "Live Chat Agents",
    "planUpgrade.detail.label.orderManagement": "Order Management",
    "planUpgrade.detail.label.inventoryManagement": "Inventory Management",
    "planUpgrade.detail.label.sameDayFoodDelivery": "Same-day Delivery Service",
    "planUpgrade.detail.label.monthlyAddon": "Monthly Add-ons Package",
    "planUpgrade.detail.label.customAi": "Custom AI response message",
    "planUpgrade.detail.label.newCustomAi": "Custom AI charactor",
    "planUpgrade.detail.label.customTheme": "Custom branding color",
    "planUpgrade.detail.label.marketingTools": "Marketing Tools",
    "planUpgrade.detail.label.salesReport": "Sales Report",
    "planUpgrade.detail.label.intelligenceAnalytics": "Intelligence Analytics",
    "planUpgrade.detail.label.openApi": "Standard Open API",
    "planUpgrade.detail.label.facebookCommentToChat": "Facebook Comment To Chat",
    "planUpgrade.detail.label.broadcast": "AI Broadcast messages",
    "planUpgrade.detail.label.qrCode": "QR Code",
    "planUpgrade.detail.label.mobileBanking": "Mobile banking",
    "planUpgrade.detail.label.creditCard": "Debit / Credit card",
    "planUpgrade.detail.label.trueMoneyWallet": "TrueMoney Wallet",
    "planUpgrade.detail.card.title.keyFeature": "Key features",
    "planUpgrade.detail.card.title.powerfulFeature": "Powerful features",
    "planUpgrade.detail.card.title.growthFeature": "Growth features",
    "planUpgrade.detail.card.title.deeplePay": "deeple pay",
    "planUpgrade.detail.tooltip.addon": "Buy more via add-on",
    "planUpgrade.card.tooltip.overCharge": "Extra charge  ฿ {{ rate }} per order",
    "planUpgrade.card.detail.description.enterprise": "Design a custom package and additional features.",
    "planUpgrade.confirmModal.detail":
      "Are you confirm to change to {{package}} with {{subscriber}} customers and {{order}} orders and total price is {{price}} baht ?",
    "planUpgrade.confirmModal.applyDetail": "The change will be applied immediately",
    "planUpgrade.Modal.creditNotEnough": "Your credits is insufficient, please add credits before upgrade package",
    "planUsage.broadcast.label.package": "Package",
    "planUsage.broadcast.label.addon": "Add-on",
    "planUsage.broadcast.title.bothChange": "Package and Add-on latest update",
    "planUsage.broadcast.title.singleChange": "{{label}} latest update",
    "planUpgrade.buyMoreChannel": "Please buy more channels before connect to Line or Facebook",
    "planUpgrade.confirmModal.paymentSummary.title": "Payment summary",
    "planUpgrade.confirmModal.upgradingTo": "Upgrading to:",
    "planUpgrade.confirmModal.addon.standard": "Basic Add-on",
    "planUpgrade.confirmModal.addon.professional": "Standard Add-on",
    "planUpgrade.confirmModal.addon.premium": "Advanced Add-on",
    "planUpgrade.confirmModal.credit": "Credits",
    "planUpgrade.confirmModal.creditDescription":
      "Credits are the returned deduction from your remaining duration of used monthly package",
    "planUpgrade.confirmModal.dueToday": "Due today",
    "planUpgrade.confirmModal.nextBillingDate": "Your next monthly bill will be renewed on",
    CUSTOM_PACKAGE: "Custom package",
    "planUpgrade.confirmModal.snackbar.requireCreditCard":
      "Please add your credit card before proceeding to upgrade your package.",
    "planUpgrade.confirmModal.snackbar.ManageBilling": "Manage Billing",
    "planUpgrade.addPaymentMethod": "Add a payment method",
    "planUpgrade.fillBillingInformation": "Fill billing information",
    "planUpgrade.startYourPackage": "Start your package",
    "planUpgrade.trialEnds": "after a free 14-day use of package.",
    "planUpgrade.startFreeTrailModalDescription": "Your 14-day free trial of deeple.ai have started.",
    "planUpgrade.confirmSelect": "Confirm ",
    "planUpgrade.freeTrial14Days": "Free trial - 14 days",

    "planUpgrade.freeTrial.title": "Flexible pricing packages that scale with your business.",
    "planUpgrade.freeTrial.subTitle": "Select a package to try deeple.ai free for 14 days",
    "planUpgrade.freeTrial.cancelDescription": "Cancellation is free of charge at any time before the trial ends.",
    "planUpgrade.freeTrial.on14DayFreeTrial": "",
    "planUpgrade.packageSummary.Subtitle.on14DayFreeTrial": "",
    "planUpgrade.packageSummary.Subtitle.upgradeOnFreeTrial":
      "Your plan will be charged after a free 14-day trial period, but your upgrade or change will take effect immediately.",
    "planUpgrade.packageSummary.Subtitle.sameSubscriptionType":
      "Upgrade or change your plan will be charged and effect immediately.",
    "planUpgrade.packageSummary.Subtitle.changeSubscriptionType":
      "Your change from a monthly to a yearly plan will take effect right now, and you will be charged immediately.",
    "planUsage.subscriptionType.annually": "Yearly",
    "planUsage.subscriptionType.monthly": "Monthly",
  },
  th: {
    "planUsage.menu": "แพ็กเกจ & การใช้งาน",
    "planUsage.addNew.teamMember": "เพิ่มสมาชิกใหม่",
    "planUsage.button.manage": "จัดการ",
    "planUsage.description.moreIntegration": "ต้องการเชื่อมต่อกับระบบอื่น?",
    "planUsage.description.moreMember": "คุณต้องการเพิ่มสมาชิก ?",
    "planUsage.description.noIntegration": "คุณสามารถเชื่อมต่อสต็อกเข้ากับระบบอื่นได้ (Lazada, Shopee, JD)",
    "planUsage.header": "แพ็กเกจ & การใช้งาน",
    "planUsage.label.usagePeriod": "ระเวลาการใช้งาน : ",
    "planUsage.label.buyIntegrationPrice": "ชำระเงินเพียง ฿ 450 / เดือน",
    "planUsage.label.buyMore": "ซื้อเพิ่ม",
    "planUsage.label.buyMoreSeat": "เพิ่มจำนวนสมาชิก",
    "planUsage.label.noIntegration": "ไม่มีการเชื่อมต่อสต็อก",
    "planUsage.label.unit.subscriber": "คน",
    "planUsage.label.unit.intent": "คำถามความรู้เฉพาะทาง",
    "planUsage.label.unit.message": "ข้อความ",
    "planUsage.label.unit.order": "รายการ",
    "planUsage.label.unit.sku": "สินค้า",
    "planUsage.label.unit.broadcast": "ข้อความ",
    "planUsage.label.unit.lineMessage": "ข้อความ",
    "planUsage.label.unit.manualApproveOrder": "รายการ",
    "planUsage.label.unlimited": "ไม่จำกัดจำนวน",
    "planUsage.label.upgrade": "อัพเกรด",
    "planUsage.label.yourPlan.imageSearchAddon": "แพ็กเกจการค้นหาสินค้าด้วยรูปภาพของคุณ :",
    "planUsage.label.yourPlan.cCommercePackage": "แพ็กเกจรายการสั่งซื้อของคุณ :",
    "planUsage.label.yourPlan.mainPackage": "แพ็กเกจของคุณ :",
    "planUsage.label.yourPlan.lineMessage": "โควต้า LINE message ของคุณ",
    "planUsage.label.yourPlan.manualApproveOrder": "คำสั่งซื้อของคุณ",
    "packageLevel.free": "แพ็กเกจฟรี",
    "packageLevel.starter_1": "แพ็กเกจ Starter-S",
    "packageLevel.starter_2": "แพ็กเกจ Starter-M",
    "packageLevel.starter_3": "แพ็กเกจ Starter-L",
    "packageLevel.standard": "แพ็คเกจเบสิค",
    "packageLevel.premium": "แพ็กเกจแอดวานซ์",
    "packageLevel.professional": "แพ็กเกจสแตนดาร์ด",
    "planUsage.label.name.mainPackage":
      "{{ level }}{{ subscriptionType }} (ลูกค้า {{subscriberQuota}} คน, {{orderQuota}} รายการสั่งซื้อ)",
    "planUsage.label.name.newPackage.mainPackage":
      "{{ level }}{{ subscriptionType }} (ลูกค้า {{subscriberQuota}} คน, {{orderQuota}} สินค้า)",
    "planUsage.title.label.imageSearch": "การค้นหาสินค้าด้วยรูปภาพ",
    "planUsage.label.unit.imageSearch": "รูป",
    "planUsage.label.yourPlan": "แพ็กเกจของคุณ :",
    "planUsage.limit.conjunction": "จาก",
    "planUsage.suffix.percentage": "% จากทั้งหมด",
    "planUsage.title.channel": "ช่องทางการเชื่อมต่อ",
    "planUsage.title.integration": "การเชื่อมต่อสต็อก",
    "planUsage.title.label.cCommercePackage": "แพ็กเกจรายการสั่งซื้อ",
    "planUsage.title.label.mainPackage": "แพ็กเกจหลัก",
    "planUsage.title.label.subscriber": "ลูกค้า",
    "planUsage.title.label.intent": "คำถามความรู้เฉพาะทาง",
    "planUsage.title.label.message": "ข้อความ",
    "planUsage.title.label.order": "รายการสั่งซื้อ",
    "planUsage.title.label.sku": "สินค้า",
    "planUsage.title.label.broadcast": "ข้อความบรอดแคสต์",
    "planUsage.title.label.lineMessage": "Push Message",
    "planUsage.title.label.manualApproveOrder": "ออเดอร์ที่ชำระผ่านการโอน",
    "planUsage.title.teamMember": "สมาชิก",
    "planUsage.tooltip.contactSale": "Please contact at sale@deeple.ai to upgrade",
    "planUpgrade.header": "เปลี่ยนแพ็กเกจการใช้งาน",
    "planUpgrade.heading.title": "ปรับเปลี่ยนแพ็กเกจการใช้งาน",
    "planUpgrade.heading.subTitle": "ได้ตามความต้องการ และความเติบโตทางธุรกิจของคุณ",
    "planUpgrade.label.recommended": "แนะนำ",
    "planUpgrade.card.perMonth": "ต่อเดือน",
    "planUpgrade.card.detail.description": "จำนวนลูกค้า {{subscriber}} คน และคำสั่งซื้อ {{order}} รายการต่อเดือน",
    "planUpgrade.card.detail.description.prefix": "",
    "planUpgrade.card.detail.description.subscriber": "จำนวนลูกค้า {{subscriber}} คน",
    "planUpgrade.card.detail.description.and": " และ ",
    "planUpgrade.card.detail.description.order": "คำสั่งซื้อ {{order}} รายการ",
    "planUpgrade.card.detail.description.perMonth": "ต่อเดือน",
    "planUpgrade.card.detail.overCharge": "ส่วนที่ชาร์จเพิ่ม",
    "planUpgrade.label.oneTimeTrial": "ทดลองใช้งานได้ 1 ครั้ง",
    "planUpgrade.detail.label.order": "รายการสั่งซื้อ",
    "planUpgrade.detail.label.message": "ข้อความ",
    "planUpgrade.detail.label.intent": "คำถามความรู้เฉพาะทาง",
    "planUpgrade.detail.label.sku": "สินค้า",
    "planUpgrade.detail.label.member": "สมาชิก",
    "planUpgrade.detail.label.channel": "ช่องทางการเชื่อมต่อ (Line หรือ Facebook)",
    "planUpgrade.detail.label.saleReport": "รายงานการขาย",
    "planUpgrade.detail.label.incomingMsg": "ข้อความจากลูกค้า",
    "planUpgrade.detail.label.imageSearch": "การค้นหาด้วยรูปภาพ (ทดลองใช้งานฟรี)",
    "planUpgrade.detail.label.subscriber": "คน",
    "planUpgrade.detail.label.subscriber.prefix": "ลูกค้า ",
    "planUpgrade.button.label.subscribePackages": "เลือกแพ็กเกจ",
    "planUpgrade.button.label.choose": "เลือกแพ็กเกจ",
    "planUpgrade.button.label.contactUs": "ติดต่อเรา",
    "planUpgrade.button.label.freeTrial": "ทดลองใช้งานฟรี",
    "planUpgrade.button.label.currentPlan": "แพ็กเกจปัจจุบัน",
    "planUpgrade.detail.tooltip.extra": "{{unit}}ที่เกินมา จะถูกเรียกเก็บเงินเพิ่มเป็นจำนวน ฿{{extraPrice}}/{{unit}}",
    "planUpgrade.title.basicPackage": "แพ็กเกจเริ่มต้น",

    "planUpgrade.card.newPlan.free": "แพ็คเกจฟรี",
    "planUpgrade.card.newPlan.free.description": "สำหรับธุรกิจขนาดเล็ก",
    "planUpgrade.card.newPlan.standard": "แพ็คเกจเบสิค",
    "planUpgrade.card.newPlan.standard.description": "สำหรับธุรกิจขนาดเล็ก",
    "planUpgrade.card.newPlan.professional": "แพ็กเกจสแตนดาร์ด",
    "planUpgrade.card.newPlan.professional.description": "สำหรับธุรกิจขนาดเล็กและขนาดกลาง",
    "planUpgrade.card.newPlan.premium": "แพ็กเกจแอดวานซ์",
    "planUpgrade.card.newPlan.premium.description": "สำหรับธุรกิจขนาดกลางและขนาดใหญ่",
    "planUpgrade.card.newPlan.enterprise": "แพ็กเกจแบบกำหนดเอง",
    "planUpgrade.card.newPlan.enterprise.description": "สำหรับธุรกิจขนาดขนาดใหญ่",
    "planUpgrade.card.newPlan.enterprise.title": "ติดต่อเรา",
    "planUpgrade.card.newPlan.detail.customer.prefix": "ลูกค้า ",
    "planUpgrade.card.newPlan.detail.customer.suffix": "คน",
    "planUpgrade.card.newPlan.detail.orders": "จำนวนออร์เดอร์",
    "planUpgrade.card.newPlan.detail.deeplePay.prefix": "รับเงินโดยตรงผ่าน ",
    "planUpgrade.card.newPlan.detail.deeplePay.suffix": "deeple pay",
    "planUpgrade.card.newPlan.detail.products.prefix": "สินค้า",
    "planUpgrade.card.newPlan.detail.products.suffix": "ชิ้น",
    "planUpgrade.card.newPlan.detail.ai": "คำถามความรู้เฉพาะทาง",
    "planUpgrade.card.newPlan.detail.broadcast": "ข้อความบรอดแคสต์ต่อเดือน",
    "planUpgrade.card.newPlan.detail.openApi.prefix": "Open API",
    "planUpgrade.card.newPlan.detail.openApi.suffix": " : Standard",
    "planUpgrade.detail.value.deeplePay": "รับเงินโดยตรงผ่าน",
    "planUpgrade.detail.label.deeplePay": "deeple pay",
    "planUpgrade.label.fee": "ค่าธรรมเนียม {{value}}%",

    "planUpgrade.title.mainPackage": "แพ็กเกจหลัก",
    "planUpgrade.title.cCommercePackage": " แพ็กเกจ C-Commerce",
    "planUpgrade.title.imageSearchPackage": "แพ็กเกจการค้นหารูปภาพด้วย AI",
    "planUpgrade.card.title.plan.free": "แพ็กเกจฟรี",
    "planUpgrade.card.title.plan.standard": "แพ็คเกจเบสิค",
    "planUpgrade.card.title.plan.premium": "แพ็กเกจแอดวานซ์",
    "planUpgrade.card.title.plan.professional": "แพ็กเกจสแตนดาร์ด",
    "planUpgrade.card.title.plan.enterprise": "แพ็กเกจแบบกำหนดเอง",
    "planUpgrade.label.contactUs": "ติดต่อเรา",
    "planUpgrade.label.contactSaleDetail": `เลือกแพ็กเกจขนาดใหญ่ ให้ตรงตามขนาดธุรกิจของคุณ`,
    "planUpgrade.detail.tooltip.contactSale": "กรุณาติดต่อเซลล์ที่ sale@deeple.ai",
    "planUpgrade.label.prefix.over": "มากกว่า",
    "planUpgrade.label.suffix.imageSearch": "รูปภาพ",
    "planUpgrade.label.suffix.order": "คำสั่งซื้อ",
    "planUpgrade.label.suffix.subscriber": "ลูกค้า {{amount}} คน",
    "planUpgrade.quota.unlimited": "ไม่จำกัดจำนวน",
    "planUpgrade.subTitle.mainPackage": "ลูกค้า {{ markLabel }} คน",
    "planUpgrade.subTitle.cCommercePackage": "{{ markLabel }} รายการสั่งซื้อ",
    "planUpgrade.card.label.mainPackage": "จำนวนลูกค้าของคุณ",
    "planUpgrade.card.label.cCommercePackage": "จำนวนยอดสั่งซื้อต่อเดือนของคุณ",
    "planUpgrade.card.slider.label.mainPackage": "ลูกค้า {{ selectedValue }} คน",
    "planUpgrade.card.slider.label.cCommercePackage": "{{ selectedValue }} รายการสั่งซื้อต่อเดือน",
    "planUpgrade.card.title.main": "แพ็กเกจหลัก",
    "planUpgrade.card.title.cCommerce": "แพ็กเกจ C-Commerce",
    "planUpgrade.card.title.imageSearch": "แพ็กเกจการค้นหารูปภาพด้วย AI",
    "upgradePlan.error.failed": "การอัพเกรดล้มเหลว!!, {{error}}",
    "upgradePlan.error.unSuccessfulDownGrade":
      "ไม่สามารถปรับลดแพ็กเกจได้ โปรดตรวจสอบให้แน่ใจว่า ความรู้เฉพาะทาง, SKUs, สมาชิกในทีม, ช่องทางเชื่อมต่อ(LINE, Facebook) และฟีเจอร์เสริม ที่มีอยู่ไม่เกินแพ็กเกจที่คุณต้องการลดระดับ",
    "planUpgrade.card.description.free": "ทดลองใช้งานฟรี",
    "planUpgrade.card.description.estimateCost": "ราคาโดยประมาณ",
    "planUpgrade.card.description.startAt": "ราคาเริ่มต้นที่",
    "planUpgrade.card.description.enterprise": "สำหรับข้อมูลเพิ่มเติม โปรด",
    "planUpgrade.detail.label.liveChatAgent": "ระบบช่วยเหลือลูกค้าผ่านแชท",
    "planUpgrade.detail.label.orderManagement": "ระบบจัดการการสั่งซื้อ",
    "planUpgrade.detail.label.inventoryManagement": "ระบบจัดการคลังสินค้า",
    "planUpgrade.detail.label.sameDayFoodDelivery": "ระบบขนส่งภายในวันเดียวกัน",
    "planUpgrade.detail.label.monthlyAddon": "ซื้อฟีเจอร์เสริมเพิ่มได้",
    "planUpgrade.detail.label.customAi": "ระบบจัดการถามตอบของบอท",
    "planUpgrade.detail.label.newCustomAi": "ระบบจัดการถามตอบของบอท",
    "planUpgrade.detail.label.customTheme": "ระบบจัดการธีมของร้านค้า",
    "planUpgrade.detail.label.marketingTools": "เครื่องมือทางการตลาด",
    "planUpgrade.detail.label.salesReport": "รายงานการขาย",
    "planUpgrade.detail.label.intelligenceAnalytics": "ระบบวิเคราะห์ข้อมูลด้วย AI",
    "planUpgrade.detail.label.openApi": "Standard Open API",
    "planUpgrade.detail.label.facebookCommentToChat": "การตอบกลับโพสต์เฟซบุ๊กอัตโนมัติ",
    "planUpgrade.detail.label.broadcast": "ข้อความบรอดแคสต์ต่อเดือน",
    "planUpgrade.detail.label.qrCode": "คิวอาร์โค้ด",
    "planUpgrade.detail.label.mobileBanking": "โมบายแบงก์กิ้ง",
    "planUpgrade.detail.label.creditCard": "บัตรเดบิต / เครดิต",
    "planUpgrade.detail.label.trueMoneyWallet": "TrueMoney Wallet",
    "planUpgrade.detail.card.title.keyFeature": "ฟีเจอร์หลัก",
    "planUpgrade.detail.card.title.powerfulFeature": "ฟีเจอร์ที่น่าสนใจ",
    "planUpgrade.detail.card.title.growthFeature": "ฟีเจอร์ช่วยเพิ่มยอดขาย",
    "planUpgrade.detail.card.title.deeplePay": "deeple pay",
    "planUpgrade.detail.tooltip.addon": "ซื้อเพิ่มเติมได้ภายหลัง",
    "planUpgrade.card.tooltip.overCharge": "ส่วนที่เกิน คิดรายการสั่งซื้อละ {{ rate }} บาท",
    "planUpgrade.card.detail.description.enterprise": "ออกแบบแพ็กเกจ และฟีเจอร์เพิ่มเติมได้ตามต้องการ",
    "planUpgrade.confirmModal.detail":
      "คุณยืนยันที่จะเปลี่ยนไปใช้ {{package}} โดยมีจำนวนลูกค้า {{subscriber}} คน และคำสั่งซื้อ {{order}} รายการ ราคารวม {{price}} บาท ใช่หรือไม่ ?",
    "planUpgrade.confirmModal.applyDetail": "การเปลี่ยนแปลงจะมีผลทันที",
    "planUpgrade.Modal.creditNotEnough": "เครดิตของคุณไม่เพียงพอ กรุณาเติมเครดิตให้เพียงพอก่อนเปลี่ยนแพคเก็จ",
    "planUsage.broadcast.label.package": "แพ็กเกจ",
    "planUsage.broadcast.label.addon": "ฟีเจอร์เสริม",
    "planUsage.broadcast.title.bothChange": "การอัพเดทแพ็กเกจ และฟีเจอร์เสริมล่าสุด",
    "planUsage.broadcast.title.singleChange": "การอัพเดท{{label}}ล่าสุด",
    "planUpgrade.buyMoreChannel": "กรุณาซื้อช่องทางเชื่อมต่อเพิ่มเติมเพื่อเชื่อมต่อไปยัง Line หรือ Facebook",
    "planUpgrade.confirmModal.paymentSummary.title": "รายละเอียดการชำระเงิน",
    "planUpgrade.confirmModal.upgradingTo": "เปลี่ยนไปยัง:",
    "planUpgrade.confirmModal.addon.standard": "เบสิค Add-on",
    "planUpgrade.confirmModal.addon.professional": "สแตนดาร์ด Add-on",
    "planUpgrade.confirmModal.addon.premium": "แอดวานซ์ Add-on",
    "planUpgrade.confirmModal.credit": "เงินเครดิต",
    "planUpgrade.confirmModal.creditDescription":
      "เงินเครดิต คือเงินชดเชยหลังการหักค่าใช้จ่ายของแพ็กเกจและฟีเจอร์เสริมรายเดือนที่ใช้ไป",
    "planUpgrade.confirmModal.dueToday": "ชำระวันนี้",
    "planUpgrade.confirmModal.nextBillingDate": "ยอดชำระในรอบบิลถัดไปจะถูกเรียกเก็บในวันที่",
    CUSTOM_PACKAGE: "แพ็กเกจแบบกำหนดเอง",
    "planUpgrade.confirmModal.snackbar.requireCreditCard": "กรุณาผูกบัตรเครดิตก่อนจะทำการเปลี่ยนแพ็คเกจ",
    "planUpgrade.confirmModal.snackbar.ManageBilling": "จัดการการเรียกเก็บเงิน",
    "planUpgrade.addPaymentMethod": "เพิ่มวิธีการชำระเงิน",
    "planUpgrade.fillBillingInformation": "กรอกข้อมูลการเรียกเก็บเงิน",
    "planUpgrade.startYourPackage": "เริ่มใช้งานแพ็กเกจ",
    "planUpgrade.trialEnds": "หลังจากสิ้นสุดช่วงทดลองใช้ 14 วัน",
    "planUpgrade.startFreeTrailModalDescription": "เริ่มทดลองใช้ deeple.ai ฟรี 14 วันได้แล้ว",
    "planUpgrade.confirmSelect": "ยืนยันการเลือก ",
    "planUpgrade.freeTrial14Days": "ทดลองใช้ 14 วัน",

    "planUpgrade.freeTrial.title": "แพ็คเกจหลากหลายราคาที่ปรับขนาดตามธุรกิจของคุณ",
    "planUpgrade.freeTrial.subTitle": "เลือกแพ็คเกจเพื่อทดลองใช้ deeple.ai ฟรี 14 วัน",
    "planUpgrade.freeTrial.cancelDescription": "ยกเลิกได้ฟรีทุกเมื่อก่อนหมดช่วงเวลาทดลองใช้",
    "planUpgrade.freeTrial.on14DayFreeTrial": "",
    "planUpgrade.packageSummary.Subtitle.on14DayFreeTrial": "",
    "planUpgrade.packageSummary.Subtitle.upgradeOnFreeTrial":
      "การอัพเกรดหรือเปลี่ยนแพลนจะถูกเรียกเก็บเงินหลังจากทดลองใช้ฟรี 14 วัน และคุณจะถูกเปลี่ยนไปใช้แพลนใหม่ที่เลือกทันที",
    "planUpgrade.packageSummary.Subtitle.sameSubscriptionType":
      "การอัพเกรดหรือเปลี่ยนแพลนจะถูกเรียกเก็บเงินและมีผลทันที",
    "planUpgrade.packageSummary.Subtitle.changeSubscriptionType":
      "หลังจากอัปเกรดจากแพลนรายเดือนเป็นรายปี แพลนของคุณจะถูกเปลี่ยนและคุณจะถูกเรียกเก็บเงินในทันที",
    "planUsage.subscriptionType.annually": "รายปี",
    "planUsage.subscriptionType.monthly": "รายเดือน",
  },
};

export default planUsage;
