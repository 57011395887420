import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import isNull from "lodash/isNull";
import { makeStyles } from "@material-ui/core";
import { Components } from "@deeple-ai/web-console";

import Button from "components/Button";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";

import COLORS from "constants/Colors";
import { MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY } from "config";

import { BROADCAST, FACEBOOK, LINE_OA, MARKETING_TOOLS, NEW_RECURRING_REQUEST, PROJECT } from "constants/Router";
import { ProjectIdType } from "types/Project";

import { BroadcastLineTable } from "./BroadcastLineTable";
import { BroadcastFacebookTable } from "./BroadcastFacebookTable";
import { BroadcastLineForm } from "./BroadcastLineForm";
import { Tab } from "./Tab";

interface BroadcastProps {
  activeTab?: "line" | "facebook";
}

const useStyle = makeStyles({
  button: {
    paddingRight: "12px !important",
    paddingLeft: "12px !important",
    color: `${COLORS.LightWhite} !important`,
    fontSize: "14px !important",
    lineHeight: "normal !important",
    backgroundColor: `${COLORS.Primary} !important`,
    "&:hover": {
      color: `${COLORS.Primary} !important`,
      backgroundColor: `${COLORS.LightWhite} !important`,
    },
  },
});

function Broadcast({ activeTab = "line" }: BroadcastProps): JSX.Element {
  // Hooks
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const history = useHistory();
  const classes = useStyle();

  const [isOpenBroadcastForm, setIsOpenBroadcastForm] = useState(false);
  const [broadcastFormId, setBroadcastFormId] = useState<string>("");
  const [isOpenModalFacebookRecurringNotification, setIsOpenModalFacebookRecurringNotification] = useState<boolean>(
    false,
  );

  // Event handlers
  const handleClickTableRowItem = (id: string) => {
    if (id) {
      setBroadcastFormId(id);
      setIsOpenBroadcastForm(true);
    }
  };

  const handleClickOpenForm = () => {
    setBroadcastFormId("");
    setIsOpenBroadcastForm(true);
  };

  const handleCloseModalFacebookRecurringNotification = () => {
    setIsOpenModalFacebookRecurringNotification(false);
    const storageValues = localStorage.getItem(MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY);

    if (isNull(storageValues)) {
      localStorage.setItem(MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY, JSON.stringify([projectId]));
    } else {
      const values = JSON.parse(storageValues);
      if (Array.isArray(values)) {
        values.push(projectId);
        localStorage.setItem(MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY, JSON.stringify(values));
      } else {
        localStorage.setItem(MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY, JSON.stringify([projectId]));
      }
    }
  };

  // Render helper functions
  const renderForm = () => {
    return activeTab === "line" ? (
      <BroadcastLineForm
        onClick={() => {
          setIsOpenBroadcastForm(false);
        }}
        onClose={() => {
          setIsOpenBroadcastForm(false);
        }}
        broadcastFormId={broadcastFormId}
      />
    ) : (
      <Grid>Facebook Form</Grid>
    );
  };

  const renderTable = () => {
    return (
      <>
        <PageTitle title={t("broadcast")} />
        <div className="w-100 h-100">
          <div className="px-3">
            <Tab
              isSelected={activeTab === "line"}
              onClick={() => history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${LINE_OA}`)}
            >
              {t("broadcast.lineOa")}
            </Tab>
            <Tab
              isSelected={activeTab === "facebook"}
              onClick={() => history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}`)}
            >
              {t("broadcast.facebook")}
            </Tab>
          </div>
          {activeTab === "line" ? (
            <BroadcastLineTable onClick={handleClickOpenForm} onClickTitle={handleClickTableRowItem} />
          ) : (
            <BroadcastFacebookTable
              onClick={() => {
                history.push(
                  `/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}/${NEW_RECURRING_REQUEST}`,
                );
              }}
            />
          )}
        </div>
      </>
    );
  };

  // Effect logic functions
  const popupModalFacebookRecurringNotification = () => {
    const isFacebookTab = activeTab === "facebook";
    const storageValues = localStorage.getItem(MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY);
    const hasProjectId = isNull(storageValues) ? false : JSON.parse(storageValues).includes(projectId);

    if (isFacebookTab && (isNull(storageValues) || !hasProjectId)) {
      setIsOpenModalFacebookRecurringNotification(true);
    }
  };

  // Effect hooks
  useEffect(() => {
    popupModalFacebookRecurringNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <div className="w-100">{isOpenBroadcastForm ? renderForm() : renderTable()}</div>
      <Components.ModalFacebookRecurringNotification
        isOpen={isOpenModalFacebookRecurringNotification}
        onClose={handleCloseModalFacebookRecurringNotification}
        buttonComponent={
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            onClick={handleCloseModalFacebookRecurringNotification}
            fullWidth
          >
            {t("Close")}
          </Button>
        }
      />
    </>
  );
}

export default Broadcast;
