import { ENV } from "config";

export const MAX_SIZE_IMPORTED_FILE = 3; // 3 MB
export const DOWNLOAD_SAMPLE_URL = `https://storage.googleapis.com/${
  ENV === "production" ? "deeple-sme" : "deeple-dev-hk-sme"
}/csv-example/upload-products-example.csv`;

export const DOWNLOAD_SAMPLE_CODE_URL = `https://storage.googleapis.com/${
  ENV === "production" ? "deeple-sme" : "deeple-dev-hk-sme"
}/csv-example/upload-digital-product-codes-example.csv`;
