import React, { FC, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Domains } from "@deeple-ai/web-console";

import { ProjectIdType } from "types/Project";
import { ChildrenWrapper } from "./styled";

type DisableFeaturePropsType = {
  isShowPremium?: boolean;
};

const { NotAllowThisFeatureModal } = Domains;

export const DisableFeature: FC<DisableFeaturePropsType> = (props) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<ProjectIdType>();
  const [isOpen, setIsOpen] = useState(false);
  const { children } = props;

  return (
    <div
      onClick={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
      className="w-100"
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <NotAllowThisFeatureModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onClickUpgrade={() => history.push(`/project/${projectId}/plan/plan-upgrade`)}
        lang={i18n.language as "th" | "en"}
      />
    </div>
  );
};
