import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";

import Modal from "components/Modal";
import AddCreditCardForm, { CreditCardDataType } from "components/AddCreditCardForm";

import COLORS from "constants/Colors";
import { CreditCardType } from "constants/Billing";
import { getGBPrimeToken } from "services/GBPrimeService";
import { ProjectIdType } from "types/Project";
import { getExpiredDate } from "utils/creditCard";
import { Utilities, Constants } from "@deeple-ai/web-console";
import { AddCreditCardVars } from "../hooks/useCreditCard";

type ModalAddCreditCardPropsType = {
  isOpen: boolean;
  onClose: () => void;
  addCreditCard: (creditCardData: { variables: AddCreditCardVars }) => void;
  isLoadingAddCreditCard: boolean;
};

const ModalAddCreditCard: FC<ModalAddCreditCardPropsType> = (props) => {
  const { isOpen, onClose, addCreditCard, isLoadingAddCreditCard } = props;
  const { projectId } = useParams<ProjectIdType>();

  const [isCreditCardError, setIsCreditCardError] = useState<string | boolean>(false);

  const handleClose = () => {
    onClose();
    setIsCreditCardError(false);
  };

  const onSubmitHandler = async ({ cardNumber, validDate, cvv, cardHolderName, cardType }: CreditCardDataType) => {
    // if all field not empty
    if (cardNumber && validDate && cvv && cardHolderName) {
      // call gbprime
      const cardNumberWithoutSpace = cardNumber.replaceAll(" ", "");
      const { month: expMonth, year: expYear } = getExpiredDate(validDate);
      const gbprimeDataFormatted = {
        rememberCard: true,
        card: {
          number: cardNumberWithoutSpace,
          expirationMonth: expMonth,
          expirationYear: expYear,
          securityCode: cvv,
          name: cardHolderName,
        },
      };

      try {
        const gbPrimeResponse = await getGBPrimeToken(gbprimeDataFormatted);
        const { resultCode, card } = gbPrimeResponse.data;

        if (resultCode !== "00") {
          throw new Error();
        }

        // call gql
        const addCreditCardGqlFormatted = {
          name: cardHolderName,
          number: card.number,
          expirationMonth: expMonth,
          expirationYear: expYear,
          token: card.token,
          brand: CreditCardType[cardType],
        };

        await addCreditCard({ variables: { projectId, creditCard: addCreditCardGqlFormatted } });
        setIsCreditCardError(false);
        Utilities.addGoogleTagEvent(Constants.gtm.GTM_EVENT.ADD_CREDIT_CARD);
        onClose();
      } catch {
        setIsCreditCardError(true);
      }
    }
  };

  return (
    <Modal maxWidth="xs" isOpen={isOpen} onClose={handleClose} closeIconColor={COLORS.Dark}>
      <AddCreditCardForm
        onSubmit={onSubmitHandler}
        error={isCreditCardError}
        isLoadingAddCreditCard={isLoadingAddCreditCard}
      />
    </Modal>
  );
};

export default ModalAddCreditCard;
