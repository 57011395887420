import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Components } from "@deeple-ai/web-console";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";

import { DeeplePayPaymentChannel, DeeplePayPaymentChannelConfiguration } from "types/DeeplePay";
import { ProjectIdType } from "types/Project";

import CreditCard from "./CreditCard";
import MobileBanking from "./MobileBanking";
import QRCode from "./QRCode";
import TrueMoneyWallet from "./TrueMoneyWallet";

import usePaymentChannel from "../hooks/usePaymentChannel";
import { DeeplePayChannelWrapper, StickyButton } from "../styled";

interface QRData {
  isActive: boolean;
}

interface ChannelData {
  isActive: boolean;
  minimum: number;
}

interface DeeplePayData {
  creditCard?: ChannelData;
  mobileBanking?: ChannelData;
  qrCash?: QRData;
  qrCredit?: QRData;
  trueWallet?: ChannelData;
}

interface DeeplePayChannelProps {
  isDeeplePayEnabled: boolean; // currently use deeple pay as payment method (false mean use bank transfer)
  isKymApproved: boolean;
  isUsingDeeplePay: boolean; // project using deeple pay package or not
}

const DeeplePayChannel: FC<DeeplePayChannelProps> = ({ isDeeplePayEnabled, isKymApproved, isUsingDeeplePay }) => {
  // General Hooks
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { deeplePayLoading, paymentChannelData = [], updatePaymentChannel } = usePaymentChannel(projectId);

  // Deeple pay form hooks
  const [deeplePayData, setDeeplePayData] = useState<DeeplePayData>({});
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isEnableSaveButton, setIsEnableSaveButton] = useState<boolean>(false);

  // Define data variables
  const qrCashData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.QR_CASH,
  );

  const qrCreditData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.QR_CREDIT,
  );

  const mobileBankingData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.MOBILE_BANKING,
  );

  const creditCardData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.CREDIT_CARD,
  );

  const trueMoneyWalletData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.TRUE_MONEY_WALLET,
  );

  const isNoData = paymentChannelData.length === 0;

  // Async functions
  const updateCreditCard = async () => {
    if (deeplePayData.creditCard) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.CREDIT_CARD,
        isActive: deeplePayData.creditCard.isActive,
        minimum: deeplePayData.creditCard.minimum,
      });
    }
  };

  const updateMobileBanking = async () => {
    if (deeplePayData?.mobileBanking) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.MOBILE_BANKING,
        isActive: deeplePayData.mobileBanking.isActive,
        minimum: deeplePayData.mobileBanking.minimum,
      });
    }
  };

  const updateQRCash = async (isForceUpdate = false) => {
    if (isForceUpdate) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.QR_CASH,
        isActive: true,
      });
    }

    if (deeplePayData?.qrCash) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.QR_CASH,
        isActive: deeplePayData?.qrCash.isActive,
      });
    }
  };

  const updateQRCredit = async () => {
    if (deeplePayData?.qrCredit) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.QR_CREDIT,
        isActive: deeplePayData.qrCredit.isActive,
      });
    }
  };

  const updateTrueWallet = async () => {
    if (deeplePayData?.trueWallet) {
      await updatePaymentChannel(projectId, {
        type: DeeplePayPaymentChannel.TRUE_MONEY_WALLET,
        isActive: deeplePayData.trueWallet.isActive,
        minimum: deeplePayData.trueWallet.minimum,
      });
    }
  };

  // Event handlers
  const handleChangeCreditCard = (isActive: boolean, minimum: number) => {
    setDeeplePayData({
      ...deeplePayData,
      creditCard: { isActive, minimum },
    });
  };

  const handleChangeMobileBanking = (isActive: boolean, minimum: number) => {
    setDeeplePayData({
      ...deeplePayData,
      mobileBanking: { isActive, minimum },
    });
  };

  const handleChangeQRCash = (isActive: boolean) => {
    setDeeplePayData({
      ...deeplePayData,
      qrCash: { isActive },
    });
  };

  const handleChangeQRCredit = (isActive: boolean) => {
    setDeeplePayData({
      ...deeplePayData,
      qrCredit: { isActive },
    });
  };

  const handleChangeTrueWallet = (isActive: boolean, minimum: number) => {
    setDeeplePayData({
      ...deeplePayData,
      trueWallet: { isActive, minimum },
    });
  };

  const handleClickSaveButton = async () => {
    try {
      setIsPending(true);

      await updateCreditCard();
      await updateMobileBanking();
      // force update QR cash to update use deeple pay in case change from bank transfer to deeple pay without changing any deeple pay config
      await updateQRCash(true);
      await updateQRCredit();
      await updateTrueWallet();

      setDeeplePayData({});
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });
    } catch (error) {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    } finally {
      setIsPending(false);
    }
  };

  // Life-cycle hooks
  useEffect(() => {
    // enable save button even no data change but change from bank transfer
    if (Object.keys(deeplePayData).length || !isDeeplePayEnabled) {
      setIsEnableSaveButton(true);
    } else {
      setIsEnableSaveButton(false);
    }
  }, [deeplePayData, isDeeplePayEnabled]);

  // Renderers;
  if (isNoData) {
    return null;
  }

  if (deeplePayLoading || isPending) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  if (!isUsingDeeplePay) {
    return (
      <DeeplePayChannelWrapper>
        <QRCode
          qrCashData={qrCashData}
          qrCreditData={qrCreditData}
          onQRCashChange={handleChangeQRCash}
          onQRCreditChange={handleChangeQRCredit}
        />
        <MobileBanking mobileBankingData={mobileBankingData} onChange={handleChangeMobileBanking} />
        <CreditCard creditCardData={creditCardData} onChange={handleChangeCreditCard} />
        <TrueMoneyWallet trueMoneyWalletData={trueMoneyWalletData} onChange={handleChangeTrueWallet} />
        <Components.CardDeeplePayContactSupport isNewShop={false} isRounded />
      </DeeplePayChannelWrapper>
    );
  }

  if (!isKymApproved) {
    return (
      <>
        <QRCode
          qrCashData={qrCashData}
          qrCreditData={qrCreditData}
          onQRCashChange={handleChangeQRCash}
          onQRCreditChange={handleChangeQRCredit}
        />
        <MobileBanking mobileBankingData={mobileBankingData} onChange={handleChangeMobileBanking} />
        <DeeplePayChannelWrapper>
          <CreditCard creditCardData={creditCardData} onChange={handleChangeCreditCard} />
          <TrueMoneyWallet trueMoneyWalletData={trueMoneyWalletData} onChange={handleChangeTrueWallet} />
          <Components.CardDeeplePayContactSupport isRounded />
        </DeeplePayChannelWrapper>
        <StickyButton>
          <Button fullWidth disabled={!isEnableSaveButton} onClick={handleClickSaveButton}>
            {t("Save")}
          </Button>
        </StickyButton>
      </>
    );
  }

  return (
    <>
      <QRCode
        qrCashData={qrCashData}
        qrCreditData={qrCreditData}
        onQRCashChange={handleChangeQRCash}
        onQRCreditChange={handleChangeQRCredit}
      />
      <MobileBanking mobileBankingData={mobileBankingData} onChange={handleChangeMobileBanking} />
      <CreditCard creditCardData={creditCardData} onChange={handleChangeCreditCard} />
      <TrueMoneyWallet trueMoneyWalletData={trueMoneyWalletData} onChange={handleChangeTrueWallet} />
      <StickyButton>
        <Button fullWidth disabled={!isEnableSaveButton} onClick={handleClickSaveButton}>
          {t("Save")}
        </Button>
      </StickyButton>
    </>
  );
};

export default DeeplePayChannel;
