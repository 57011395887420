import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Components, Enums } from "@deeple-ai/web-console";

import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";
import { OnClickRadio } from "components/Radio";

import { GUIDELINE } from "graphql/guideline/query";
import { UPDATE_PAYMENT_GUIDELINE } from "graphql/guideline/mutation";

import { GuidelineQueryType } from "types/Guideline";
import { ProjectIdType } from "types/Project";

import { GuidelineContext } from "utils/context";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useGetProject from "utils/hooks/useGetProject";

import OldPayment from "./BankPaymentChannel/OldPayment";
import { DeeplePayChannel } from "./DeeplePayChannel/index";
import { Header, PaymentChannel } from "./Header";
import { Wrapper } from "./styled";

const NewPaymentChannel: FC = () => {
  // General hooks
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  const { i18n } = useTranslation();
  const { isDeeplePayEnabled, isKymApproved, isUsingDeeplePay, loading: getProjectLoading } = useGetProject(projectId, {
    fetchPolicy: "network-only",
  });

  // Switcher's hooks
  const [isDeeplePay, setIsDeeplePay] = useState<boolean>(isDeeplePayEnabled);

  // Deeple pay's hooks
  const { handleFetchGuideline } = useContext(GuidelineContext);
  const [isShowDeeplePayPreview, setIsShowDeeplePayPreview] = useState<boolean>(false);
  const [isShowGuideline, setIsShowGuideline] = useState<boolean>(false);
  const [updatePaymentGuideline] = useMutation(UPDATE_PAYMENT_GUIDELINE);
  const { data: guidelineData, loading: guidelineQueryLoading } = useQuery<GuidelineQueryType>(GUIDELINE, {
    variables: {
      projectId,
    },
  });

  // Bank payment's hooks
  const [isShowBankPaymentPreview, setIsShowBankPaymentPreview] = useState<boolean>(false);

  // Deeple pay's variables
  const isCompletePaymentSetting = guidelineData?.guideline?.hasPaymentMethod;

  // Switcher event handlers
  const handleClickSwitcher: OnClickRadio = (_event, value) => {
    setIsDeeplePay(PaymentChannel.DeeplePay === value);
  };

  // Deeple pay event handlers
  const handleClickDeeplePayExampleLink = () => {
    setIsShowDeeplePayPreview(!isShowDeeplePayPreview);
  };

  const handleClickLearnMoreLink = () => {
    window.open("https://tutorials.deeple.ai/deeple-pay", "_blank");
  };

  const handleClosePaymentGuidelineModal = () => {
    setIsShowGuideline(false);
  };

  const handleConnectAccount = async () => {
    await updatePaymentGuideline({ variables: { projectId } });
    handleFetchGuideline();
    setIsShowGuideline(false);
  };

  const handleGotoSettingBillingInformation = async () => {
    await updatePaymentGuideline({ variables: { projectId } });
    setIsShowGuideline(false);
    handleFetchGuideline();
    history.push(`/project/${projectId}/payment-method/transaction?kyc=true`);
  };

  // Bank payment's event handlers
  const handleClickBankPaymentExampleLink = () => {
    setIsShowBankPaymentPreview(!isShowBankPaymentPreview);
  };

  // Life-cycle hooks
  useEffect(() => {
    if (isCompletePaymentSetting === false) {
      setIsShowGuideline(true);
    }
  }, [isCompletePaymentSetting]);

  useEffect(() => {
    setIsDeeplePay(isDeeplePayEnabled);
  }, [isDeeplePayEnabled]);

  // Renderers
  if (getProjectLoading || guidelineQueryLoading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  return (
    <>
      <Wrapper container alignContent="flex-start" className={isDesktop ? "" : "mobile"}>
        <Header
          isDeeplePay={isDeeplePay}
          onClickBankPaymentExampleLink={handleClickBankPaymentExampleLink}
          onClickDeeplePayExampleLink={handleClickDeeplePayExampleLink}
          onClickLearnMoreLink={handleClickLearnMoreLink}
          onClickSwitch={handleClickSwitcher}
        />

        {isDeeplePay ? (
          <DeeplePayChannel
            isDeeplePayEnabled={isDeeplePayEnabled}
            isKymApproved={isKymApproved}
            isUsingDeeplePay={isUsingDeeplePay}
          />
        ) : (
          <OldPayment />
        )}
      </Wrapper>

      <Components.PaymentGuidelineModal
        lang={i18n.language}
        isOpen={isShowGuideline}
        onClickDone={handleConnectAccount}
        onClickSetting={handleGotoSettingBillingInformation}
        onClose={handleClosePaymentGuidelineModal}
      />

      <Components.ModalPaymentPreview
        isOpen={isShowDeeplePayPreview}
        lang={i18n.language}
        type={Enums.PaymentType.DeeplePay}
        onClose={handleClickDeeplePayExampleLink}
      />

      <Components.ModalPaymentPreview
        isOpen={isShowBankPaymentPreview}
        lang={i18n.language}
        type={Enums.PaymentType.BankPayment}
        onClose={handleClickBankPaymentExampleLink}
      />
    </>
  );
};

export default NewPaymentChannel;
