import { PHONE_NUMBER_LENGTH } from "constants/Auth";

const registerWithEmail = {
  en: {
    CREATE_ACCOUNT: "Create Account",
    EMAIL: "Email",
    ENTER_YOUR_EMAIL: "Enter your email",
    ENTER_YOUR_NAME: "Enter your name",
    ENTER_YOUR_PASSWORD: "Enter your password",
    ENTER_YOUR_PASSWORD_CONFIRMATION: "Enter your confirm password",
    ENTER_YOUR_PHONE_NUMBER: `Enter a ${PHONE_NUMBER_LENGTH} digit phone number`,
    ENTER_YOUR_REF_CODE: "Enter reference code",
    HAVE_ACCOUNT: "Already have an accout",
    LOG_IN_HERE: "Log in here",
    NAME: "Name",
    PASSWORD: "Password",
    PASSWORD_CONFIRMATION: "Confirm password",
    PHONE_NUMBER: "Phone number",
    PHONE_NUMBER_HELP_TEXT:
      "Your phone number will only be used for better support experience. It will not be shared to third-party.",
    REF_CODE: "Reference code",
    SIGN_UP: "Sign up to deeple",
  },
  th: {
    CREATE_ACCOUNT: "สร้างบัญชีผู้ใช้",
    EMAIL: "อีเมล",
    ENTER_YOUR_EMAIL: "กรอกอีเมลของคุณ",
    ENTER_YOUR_NAME: "กรอกชื่อของคุณ",
    ENTER_YOUR_PASSWORD: "กรอกรหัสผ่านของคุณ",
    ENTER_YOUR_PASSWORD_CONFIRMATION: "กรอกยืนยันรหัสผ่านของคุณ",
    ENTER_YOUR_PHONE_NUMBER: `กรอกหมายเลขโทรศัพท์ ${PHONE_NUMBER_LENGTH} หลัก`,
    ENTER_YOUR_REF_CODE: "กรอกรหัสอ้างอิง",
    HAVE_ACCOUNT: "คุณมีบัญชีผู้ใช้แล้วใช่ไหม",
    LOG_IN_HERE: "เข้าสู่ระบบที่นี่",
    NAME: "ชื่อ",
    PASSWORD: "รหัสผ่าน",
    PASSWORD_CONFIRMATION: "ยืนยันรหัสผ่าน",
    PHONE_NUMBER: "หมายเลขโทรศัพท์",
    PHONE_NUMBER_HELP_TEXT:
      "หมายเลขโทรศัพท์มีไว้เพื่อให้ลูกค้าเข้าถึงประสบการณ์ในการช่วยเหลือที่ดียิ่งขึ้น จะไม่เผยแพร่ให้บุคคลที่สามใช้งานเด็ดขาด",
    REF_CODE: "รหัสอ้างอิง",
    SIGN_UP: "สร้างบัญชีใหม่",
  },
};

export default registerWithEmail;
