import React, { FC } from "react";
import { TableHead, Table, TableRow, TableCell, TableBody, TableContainer } from "@material-ui/core";

import Grid from "components/Grid";
import Typography from "components/Typography";

export const PricingLink = () => <a href="https://www.deeple.ai/pricing"> (https://www.deeple.ai/pricing) </a>;
export const DeepleLink = () => <a href="https://www.deeple.ai/"> (https://www.deeple.ai/) </a>;

const feeHeaders = ["ช่องทางการชำระเงิน", "Free Plan", "Basic Plan", "Standard Plan", "Advanced Plan", "หมายเหตุ"];
const withdrawHeaders = ["ช่องทางการชำระเงิน", "สถานะการชำระเงิน", "การถอนเงินจาก deeple pay"];

const feeDetails = [
  {
    paymentType: "คิวอาร์โค้ด",
    freePlan: "1.25%",
    basicPlan: "0.95%",
    standardPlan: "0.75%",
    advancedPlan: "0.55%",
    note: "-",
  },
  {
    paymentType: "โมบายแบงก์กิ้ง",
    freePlan: "1.25%",
    basicPlan: "0.95%",
    standardPlan: "0.75%",
    advancedPlan: "0.55%",
    note: "*ขั้นต่ำ 15 บาท",
  },
  {
    paymentType: "บัตรเดบิต/เครดิต",
    freePlan: "3.25%",
    basicPlan: "2.95%",
    standardPlan: "2.75%",
    advancedPlan: "2.55%",
    note: "-",
  },
  {
    paymentType: "ทรูมันนี่วอลเล็ต",
    freePlan: "2.95%",
    basicPlan: "2.65%",
    standardPlan: "2.45%",
    advancedPlan: "2.25%",
    note: "-",
  },
];

const withdrawDetails = [
  {
    paymentType: "คิวอาร์โค้ด",
    status: "Completed",
    withdrawal: "ตามเวลาจริง",
  },
  {
    paymentType: "โมบายแบงก์กิ้ง",
    status: "Completed",
    withdrawal: "ตามเวลาจริง",
  },
  {
    paymentType: "ทรูมันนี่วอลเล็ต",
    status: "Processing",
    withdrawal: "ตามเวลาจริง",
  },
  {
    paymentType: "บัตรเดบิต/เครดิต*",
    status: "Completed",
    withdrawal: "เมื่อสถานะ completed (รอระบบสรุปยอดชำระเงินเวลา 21.00 น.)",
  },
];

const ScopeOfService: FC = () => {
  return (
    <Grid item xs={12}>
      <Typography variant="title2" color="primary" className="pt-4 pb-4 text-center">
        ขอบเขตการให้บริการ
      </Typography>
      <Typography variant="body3" color="darkMed">
        ขอบเขตการให้บริการฉบับนี้จะต้องอ่านประกอบกับและถือเป็นส่วนหนึ่งของข้อตกลงการให้บริการ
        ท่านตกลงและรับทราบว่าข้อกำหนดใดที่ระบุในขอบเขตการให้บริการนี้อาจถูกเพิ่มเติม เปลี่ยนแปลง
        หรือแก้ไขได้ทุกเมื่อหากมีการเปลี่ยนแปลง
        หรือการเพิ่มเติมบริการและ/หรือส่วนใดของบริการและท่านตกลงผูกพันตามข้อกำหนดที่เพิ่มเติม
        เปลี่ยนแปลงหรือแก้ไขนั้นทุกประการ
      </Typography>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        1. การบริการของบริษัทฯ
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.1) บริษัทฯ ให้บริการระบบแชทบอทปัญญาประดิษฐ์ขั้นสูง (“AI Chatbot”)
          และระบบการบริหารการจัดเก็บข้อมูลซึ่งรวมถึงคำสั่งอย่างมืออาชีพ การบริหารสินค้าคงคลัง การตอบคำถามตลอด 24 ชั่วโมง
          และตัวแทนในการตอบแชท (Live Chat) เครื่องมือทางการตลาด และรายงานการขายพร้อมด้วยแผนผังและสถิติแสดงยอดขายรวม
          คำสั่งซื้อ ลูกค้า และมูลค่าคำสั่งซื้อเฉลี่ยในแต่ละช่วงเวลาผ่านระบบซอฟต์แวร์ และ/หรือ
          แพลตฟอร์มเทคโนโลยีเพื่อช่วยพัฒนาประสิทธิภาพการขายในกิจการของท่าน (ต่อไปนี้จะเรียกรวมกันว่า “บริการพื้นฐาน”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.2) หากท่านประสงค์ ท่านอาจใช้บริการทางเลือก ดังต่อไปนี้ได้ (“บริการทางเลือก”)
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (1.2.1) บริการเพิ่มเติมในฐานะรายการเสริมจากบริการพื้นฐานเพื่อพัฒนาความสัมพันธ์ของท่านกับลูกค้าของท่าน
            (“บริการเสริม”)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (1.2.2) บริการจัดส่งโดยบริษัทขนส่งซึ่งเป็นบุคคลภายนอกเพื่อช่วยจัดส่งสินค้าของท่าน (“บริการจัดส่ง”)
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.3) บริษัทฯ จะให้บริการพื้นฐานและบริการทางเลือก (เรียกรวมกันว่า “บริการ”) ผ่านเว็บไซต์
          <DeepleLink /> แอพพลิเคชั่น และ/หรือ แพลตฟอร์มของคู่ค้าของบริษัทฯ
          หรือคู่ค้าอื่นตามสัญญาที่บริษัทฯอาจเข้าผูกพันในอนาคต ( แต่ละแห่งเรียกว่า“แพลตฟอร์ม”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.4) ท่านต้องสร้างแชทบอทโดยจัดเตรียมข้อมูลร้านค้า รายละเอียด ประเภท และรายการของสินค้า
          วิธีการชำระเงินและวิธีการจัดส่ง ระยะเวลาการจัดส่ง
          และข้อมูลของการสนทนาในบัญชีร้านค้าที่เปิดกับแพลตฟอร์มของบุคคลภายนอก
          ซึ่งแพลตฟอร์มจะสร้างระบบจัดเก็บให้กับร้านค้าของท่านและใช้ AI Chatbot กับช่องทางการสนทนาของบัญชีร้านค้าของท่าน
          (“ช่องทางการสนทนา” และเรียกรวมกันว่า “โครงการ”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.5) เมื่อท่านเข้าผูกพันกับลูกค้าและ/หรือสมาชิก บริษัทฯ โดย AI Chatbot
          จะเก็บและบันทึกโดยทันทีซึ่งรายละเอียดการติดต่อของลูกค้าและ/หรือสมาชิกของท่าน
          ประวัติและความสนใจในการซื้อของบุคคลดังกล่าว
          และการสื่อสารใดในช่องทางการสนทนาระหว่างลูกค้าของท่านและ/หรือสมาชิกและ AI Chatbot
          ในฐานข้อมูลเพื่อปรับแต่งบริการให้เข้ากับบุคคลดังกล่าวและพัฒนาบริการต่อไป
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.6) ท่านตกลงและยืนยันว่าการสื่อสารโดย AI Chatbot
          ในช่องทางการสนทนาจะถูกสร้างโดยข้อมูลที่จัดให้โดยท่านและ/หรือสมาชิกของโครงการและท่านจะรับผิดชอบต่อความเสียหายโดยตรง
          ความเสียหายโดยอ้อม ความเสียหายพิเศษหรือความเสียหายสืบเนื่องที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการสื่อสารดังกล่าว
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.7) บริษัทฯ อาจบันทึกและ/หรือประมวลผลข้อมูลที่เกี่ยวข้องกับโครงการและ/หรือการกระทำใด
          ที่เกี่ยวข้องกับการใช้บริการเพื่อวัตถุประสงค์ในการพัฒนาและให้บริการ ในกรณีนี้
          ท่านตกลงที่จะรักษาและแก้ไขปรับปรุงข้อมูลที่เกี่ยวข้องกับโครงการให้เป็นปัจจุบันในช่วงเวลาที่เหมาะสมอยู่เสมอเพื่อให้ข้อมูลดังกล่าวนั้นถูกต้อง
          เป็นปัจจุบัน และครบถ้วนในระหว่างระยะเวลาการให้บริการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.8) บริษัทฯ
          สงวนสิทธิในการเปลี่ยนแปลงและ/หรือยกเลิกด้านใดของบริการหรือส่วนใดของบริการตามที่จำเป็นเพื่อดำเนินการหรือปรับปรุงบริการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.9) ท่านตกลงและรับทราบว่าบริษัทฯ
          อาจแก้ไขปรับปรุงบริการให้เป็นปัจจุบันเพื่อพัฒนาประสิทธิภาพการทำงานหรือปรับปรุงปัญหาด้านความปลอดภัยซึ่งท่านอาจต้องติดตั้งการปรับปรุงแก้ไขนั้นกับบริการ
          การไม่ติดตั้งการปรับปรุงแก้ไขดังกล่าวหรือการเลือกไม่ให้มีการปรับปรุงแก้ไขโดยอัตโนมัติ
          ท่านอาจไม่สามารถใช้บริการต่อเนื่องไปได้ ในกรณีนี้ บริษัทฯ
          ไม่รับประกันว่าการแก้ไขปรับปรุงดังกล่าวจะยังใช้กับอุปกรณ์หรือระบบของท่านได้
          การแก้ไขปรับปรุงบริการทั้งหมดอยู่ภายใต้ขอบเขตการให้บริการและข้อตกลงการให้บริการเว้นแต่บริษัทฯ
          จะกำหนดไว้เป็นประการอื่นใด
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        2. บัญชีของท่าน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.1) ท่าน รวมถึงสมาชิก (ตามที่นิยามไว้ด้านล่างนี้) ต้องสร้างบัญชีกับบริษัทฯ (<strong>“บัญชี”</strong>)
          โดยจะต้องให้ชื่อ อีเมล์ ชื่อผู้ใช้และรหัสผ่านแก่บริษัทฯ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.2) ท่านจะต้องทำหน้าที่เป็นผู้บริหารจัดการ (<strong>“แอดมิน”</strong>) และตกลงว่า (ก)
          ท่านเป็นเจ้าของและมีอำนาจอย่างสมบูรณ์ในการดำเนินการและจัดการโครงการและสามารถควบคุมการเข้าถึงโครงการโดยเจ้าหน้าที่หรือลูกจ้างทั้งหมดของท่าน
          (<strong>“สมาชิก”</strong>) และ (ข) การใช้บัญชีถือเป็นการลงลายมือชื่ออิเล็กทรอนิกส์ภายใต้กฎหมายที่เกี่ยวข้อง
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.3) การใดที่ได้กระทำลง หรือดำเนินการ
          หรือเกิดขึ้นโดยแอดมินและ/หรือสมาชิกมีผลผูกพันและสมบูรณ์เสมือนหนึ่งว่าท่านได้กระทำการนั้นด้วยตนเองโดยไม่จำต้องลงลายมือชื่อในเอกสารอื่นใด
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.4) ท่าน (รวมถึงแอดมินและสมาชิกอื่น) ต้อง (ก) รักษาบัญชีให้ปลอดภัยและเป็นความลับ (ข)
          ไม่อนุญาตให้ผู้อื่นใช้หรือโอนบัญชีให้แก่บุคคลหรือนิติบุคคลอื่น และ (ค)
          แจ้งให้บริษัทฯทราบทันทีถึงการใช้หรือเข้าถึงบัญชีและ/หรือโครงการที่ไม่ได้รับอนุญาต
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        3. แผนการบริการ (Package) และค่าธรรมเนียม
      </Typography>
      <div className="pl-4">
        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (3.1) แผนการหลัก
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.1) ท่านอาจเลือกแผนการบริการหลักซึ่งปรับแต่งให้สอดคล้องกับ (ก) จำนวนสมาชิกของท่าน และ (ข)
            คำสั่งรายเดือนของท่าน (<strong>“แผนการหลัก”</strong>) ตามอัตราที่กำหนดในเว็บไซต์ของบริษัทฯ
            <PricingLink /> ก่อนใช้บริการ (<strong>“ค่าธรรมเนียม”</strong>)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.2) บริการของบริษัทฯ
            ที่จัดให้แก่ท่านอาจถูกระงับชั่วคราวในกรณีที่จำนวนสมาชิกเกินจำนวนยอดสมาชิกที่อนุญาตตามแผนการหลัก ในกรณีนี้
            ท่านอาจสมัครใช้แผนการหลักเพิ่มเติมเพื่อใช้บริการต่อไปได้ในอัตราที่กำหนดบนเว็บไซต์ของบริษัทฯ
            <PricingLink />
            และแผนการหลักเพิ่มเติมนี้จะเป็นแผนการหลักสำหรับรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป
            เพื่อวัตถุประสงค์ของข้อกำหนดข้อนี้ สมาชิกหมายถึงลูกค้าที่มีปฏิสัมพันธ์กับ AI Chatbot
            ในช่องทางการสนทนาไม่ว่าธุรกรรมการขายนั้นจะสำเร็จหรือไม่ก็ตาม
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.3) หากจำนวนคำสั่งรายเดือนที่สำเร็จเกินจำนวนยอดคำสั่งรายเดือนที่อนุญาตภายใต้แผนการหลัก
            ให้คิดค่าธรรมเนียมเกินส่วนสำหรับคำสั่งซื้อที่เกินมานั้นตามอัตราที่กำหนดบนเว็บไซต์ของบริษัทฯ
            <PricingLink /> (<strong>“ค่าธรรมเนียมเกินส่วน”</strong>)
          </Typography>
        </div>

        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (3.2) บริการเสริม
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.2.1) ท่านตกลงชำระค่าธรรมเนียมให้บริษัทฯ ตามอัตราที่กำหนดบนเว็บไซต์ <PricingLink />
            ก่อนใช้บริการเสริม (<strong>“ค่าธรรมเนียมการใช้บริการเสริม”</strong>)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.2.2)
            บริการเสริมบางประเภทกำหนดจำนวนยอดการใช้งานรายเดือนไว้และหากจำนวนการใช้งานของท่านเกินจำนวนยอดบริการเสริมที่ท่านเลือก
            ค่าธรรมเนียมสำหรับส่วนที่เกินกำหนดนั้นจะคิดตามอัตราที่กำหนดไว้บนเว็บไซต์ของบริษัทฯ
            <PricingLink /> (<strong>“ค่าธรรมเนียมเกินส่วนสำหรับบริการเสริม</strong>”)
          </Typography>
        </div>

        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (3.3) บริการจัดส่ง
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.3.1) ท่านต้องเติมเงินในกระเป๋าเงินอิเล็กทรอนิกส์สำหรับการจัดส่ง (
            <strong>“กระเป๋าเงินสำหรับการจัดส่ง”</strong>) ก่อนใช้บริการจัดส่ง
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.3.2) เมื่อจัดส่งเสร็จเรียบร้อยแล้ว บริษัทฯ จะหักเงินต่อไปนี้จากกระเป๋าเงินสำหรับการจัดส่ง (ก)
            ค่าธรรมเนียมการจัดส่ง (รวมถึงภาษีและภาษีมูลค่าเพิ่ม)
            ซึ่งจะกำหนดตามอัตราที่กำหนดโดยบริษัทขนส่งซึ่งเป็นบุคคลภายนอกที่ท่านเลือก (ข) ค่าใช้จ่ายอื่น ๆ
            ที่เกิดขึ้นตามสมควรที่เกี่ยวเนื่องกับบริการจัดส่ง (เรียกรวมกันว่า <strong>“ค่าธรรมเนียมการจัดส่ง”</strong>)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.3.3)
            ท่านตกลงและรับทราบว่าค่าธรรมเนียมการจัดส่งที่แสดงไว้ในเบื้องต้นในขณะทำการว่าจ้างนั้นเป็นค่าธรรมเนียมโดยประมาณซึ่งพิจารณาจากสถานที่ที่ให้โดยท่านหรือลูกค้าของท่าน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.3.4) หากท่านเลือกการส่งของแบบชำระเงินสด
            ท่านตกลงและอนุญาตให้บริษัทส่งของซึ่งเป็นบุคคลภายนอกเก็บค่าธรรมเนียมการจัดส่งจากลูกค้าของท่านได้โดยตรง
          </Typography>
        </div>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        4. เงื่อนไขการชำระเงิน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.1) ค่าบริการและค่าธรรมเนียมการใช้บริการเสริมต้องชำระล่วงหน้าในแต่ละเดือน
          หากท่านสมัครบริการเสริมบางอย่างก่อนรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป ให้คิดค่าบริการในอัตราตามสัดส่วน
          (Pro-Rata Rate) ตัวอย่างเช่น หากสมัครแผนการหลักในวันที่ 5 ท่านจะถูกคิดเงินในวันที่ 5 ของทุกเดือน
          เว้นแต่จะตรงกับวันที่ 29 วันที่ 30 และวันที่ 31 ซึ่งในกรณีนี้ท่านจะถูกคิดเงินในวันแรกของเดือนถัดไป
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.2) หากท่านซื้อแผนบริการหลักเพิ่มเติมตามข้อ 3.1.2 ก่อนรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป
          ค่าบริการที่เกี่ยวข้องจะถูกคิดทันทีเมื่อสมัครใช้โดยคิดตามสัดส่วนสำหรับการจ่ายค่าบริการรายเดือนส่วนที่เหลือ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.3) ค่าธรรมเนียมเกินส่วน ค่าธรรมเนียมเกินส่วนสำหรับบริการเสริม
          และ/หรือค่าธรรมเนียมใดที่เกิดขึ้นตามสมควรที่เกี่ยวเนื่องกับการใช้บริการของท่านจะต้องชำระจนเต็มจำนวน ณ
          สิ้นรอบการจ่ายค่าบริการรายเดือนในแต่ละรอบ (<strong>“ค่าธรรมเนียมชำระในภายหลัง”</strong>)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.4) ค่าบริการ ค่าธรรมเนียมการใช้บริการเสริม และค่าธรรมเนียมชำระในภายหลัง (<strong>“ค่าธรรมเนียม”</strong>)
          จะต้องชำระผ่านบัตรเครดิตหรือบัตรเดบิตตามข้อมูลที่ให้บริษัทฯ และท่านต้องอนุญาตให้บริษัทฯ
          หักค่าธรรมเนียมดังกล่าวจากบัตรเครดิตหรือบัตรเดบิตนั้นได้
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.5) หากชำระเงินข้ามประเทศหรือชำระด้วยเงินตราต่างประเทศ
          ท่านจะต้องรับผิดชอบส่วนต่างอัตราแลกเปลี่ยนหรือค่าธรรมเนียมเพิ่มเติมที่เกี่ยวข้องแต่เพียงผู้เดียวและอนุญาตให้บริษัทฯ
          หักเงินจำนวนดังกล่าวจากบัตรเครดิตหรือบัตรเดบิตในทันที
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.6) หากท่านไม่ดำเนินการชำระเงินค่าธรรมเนียมส่วนใดหรือปรากฎว่าบริษัทฯ
          ไม่สามารถหักเงินจากบัตรเครดิตหรือบัตรเดบิตของท่านได้ บริษัทฯ จะแจ้งให้ท่านชำระเงินในทันที
          หากไม่ดำเนินการดังกล่าว บริษัทฯ สงวนสิทธิในการระงับการให้บริการจนกว่าท่านจะชำระเงิน
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.7) ค่าธรรมเนียมที่ต้องชำระแก่บริษัทฯ ไม่สามารถขอคืน แลกเปลี่ยน โอน หรือและคืนได้ไม่ว่าในกรณีใด
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.8) ค่าบริการ ค่าธรรมเนียมการใช้บริการเสริม ค่าจัดส่ง ค่าธรรมเนียมชำระในภายหลัง
          และจำนวนเงินทั้งปวงที่ถึงกำหนดต้องชำระภายใต้การใช้บริการจะต้องชำระแก่บริษัทฯ เต็มจำนวนโดยไม่หักกลบลบหนี้
          คัดค้าน หรือหักออกใดเว้นแต่กรณีการหักภาษี ณ ที่จ่ายตามกฎหมายแห่งประเทศไทย ซึ่งหนังสือรับรองการหัก ณ
          ที่จ่ายจะต้องจัดส่งแก่บริษัทฯ พร้อมค่าธรรมเนียมดังกล่าวข้างต้น
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.9) ท่านสามารถตรวจสอบรายการแผนการชำระเงินหลักและบริการเสริมของท่านได้
          รวมทั้งสามารถตรวจสอบรายการค่าใช้จ่ายของท่านได้ที่หน้ารายการค่าใช้จ่ายบนเว็บไซต์ของเรา
          <PricingLink /> และค่าธรรมเนียมอื่นใดที่อาจเกิดขึ้นโดยเข้าไปดูที่โครงการของท่าน
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        5. การชำระค่าธรรมเนียม
      </Typography>
      <div className="pl-4">
        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (5.1) อัตราค่าธรรมเนียม
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.1.1) ค่าธรรมเนียมสำหรับการรับการชำระเงิน (Transaction Fee) ในกรณีรับชำระเงิน บริษัทฯ
            จะเก็บค่าธรรมเนียมการรับชำระเงิน ในอัตราร้อยละ [•] ของยอดขายของแต่ละรายการที่สั่งซื้อ (ไม่รวมภาษีมูลค่าเพิ่ม
            7%) ตามอัตราที่ระบุในเอกสารแนบท้าย 1
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.1.2) ค่าธรรมเนียมสำหรับการถอนเงิน (Withdrawal Fee) บริษัทฯ จะเก็บค่าธรรมเนียมการถอนเงินครั้งละ 15 บาท
            (รวมภาษีมูลค่าเพิ่ม 7%) สำหรับทุกแผนการบริการ
          </Typography>
        </div>
        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (5.2) ช่องทางการชำระเงิน
        </Typography>
        <div className="pl-3">
          <Typography variant="body3" color="darkMed" className="pb-1">
            ท่านจะต้องชำระค่าธรรมเนียมให้บริษัทฯ ผ่านทางแอพพลิเคชัน deeple pay (“deeple pay”)
            โดยดำเนินการผ่านทางช่องทางการชำระเงินดังต่อไปนี้
          </Typography>
        </div>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.2.1) คิวอาร์โค้ด (QR Code) ซึ่งรองรับการใช้งานผ่านการแสกนบนแอพพลิเคชั่นทุกธนาคาร
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.2.2) โมบายแบงก์กิ้ง (Mobile Banking) ได้แก่ แอพพลิเคชั่น SCB Easy ของธนาคารไทยพาณิชย์ KPLUS
            ของธนาคารกสิกรไทย หรือ KMA ของธนาคารกรุงศรีอยุธยา โดยจะต้องชำระขั้นต่ำ 20 บาท
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.2.3) บัตรเครดิตหรือบัตรเดบิตของ Visa Mastercard และ JCB
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (5.2.4) กระเป๋าเงินอิเล็กทรอนิกส์ (E-Wallet) ได้แก่ ทรูมันนี่วอลเล็ต (True Money) ทั้งนี้
            ระบบจะแสดงสถานะการชำระเงินและการถอนเงินจาก deeple pay ตามที่ระบุในเอกสารแนบท้าย 2
          </Typography>
        </div>
        <Typography variant="title8" color="primary" className="pt-2 pb-1">
          (5.3) การผูกบัญชีธนาคาร และการยืนยันบัญชีธนาคาร
        </Typography>
        <div className="pl-3">
          <Typography variant="body3" color="darkMed" className="pb-1">
            เพื่อยืนยันว่าท่านไม่มีส่วนเกี่ยวข้องกับธุรกรรมการฟอกเงิน หรือคดีทุจริตใด ๆ
            และเพื่อป้องกันการโจรกรรมหรือแอบอ้างตัวตนในการทำธุรกรรมผ่าน AI Chatbot ท่านจะต้องดำเนินการ ดังต่อไปนี้
          </Typography>
          <div className="pl-4">
            <Typography variant="body3" color="darkMed" className="pb-1">
              (5.3.1) การระบุตัวตน ท่านจะต้องกรอกข้อมูลบุคคลธรรมดาหรือข้อมูลนิติบุคคล พร้อมทั้งแนบเอกสารประกอบ ดังนี้
            </Typography>
            <div className="pl-4">
              <Typography variant="body3" color="darkMed" className="pb-1">
                (1) กรณีบุคคลธรรมดา ได้แก่ บัตรประจำตัวประชาชนที่ไม่หมดอายุซึ่งแสดงรูปใบหน้าและข้อมูลในบัตรอย่างชัดเจน
              </Typography>
              <Typography variant="body3" color="darkMed" className="pb-1">
                (2) กรณีนิติบุคคล ได้แก่ ใบทะเบียนพาณิชย์ หนังสือรับรองนิติบุคคลหรือใบทะเบียนภาษีมูลค่าเพิ่ม
              </Typography>
            </div>
            <Typography variant="body3" color="darkMed" className="pb-1">
              (5.3.2) การยืนยันบัญชีธนาคาร ท่านจะต้องกรอกรายละเอียดบัญชีธนาคาร พร้อมแนบเอกสารประกอบ ดังนี้
            </Typography>
            <div className="pl-4">
              <Typography variant="body3" color="darkMed" className="pb-1">
                (1) สมุดบัญชีธนาคาร หรือเอกสารที่ออกโดยธนาคารซึ่งปรากฎชื่อเจ้าของบัญชีตรงกับชื่อที่ใช้ในการระบุตัวตน
              </Typography>
              <Typography variant="body3" color="darkMed" className="pb-1">
                (2) ในกรณีบัญชีกระแสรายวันหรือบัญชีเดินสะพัด
                เอกสารที่ออกโดยธนาคารซึ่งปรากฎชื่อเจ้าของบัญชีตรงกับชื่อที่ใช้ในการระบุตัวตน และระบุชื่อเจ้าของบัญชี
                และเลขที่บัญชีอย่างชัดเจน เช่น หนังสือรับรองการมีบัญชีเงินฝาก หรือหนังสือรับรองการเปิดบัญชี
                ใบเสร็จรับเงินหรือใบแจ้งเงินเข้าบัญชีกระแสรายวัน รายการเดินบัญชีเงินฝากกระแสรายวัน (Statement)
              </Typography>
            </div>
          </div>
        </div>
        <div className="px-5 pt-4">
          <Typography variant="title7" color="primary" className="pt-2 pb-3">
            (1) อัตราค่าธรรมเนียมการชำระเงิน*
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {feeHeaders.map((feeHeader) => (
                    <TableCell key={`${feeHeader}`}>{feeHeader}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {feeDetails.map((feeDetail) => (
                  <TableRow key={feeDetail.paymentType}>
                    <TableCell component="th" scope="row">
                      {feeDetail.paymentType}
                    </TableCell>
                    <TableCell>{feeDetail.freePlan}</TableCell>
                    <TableCell>{feeDetail.basicPlan}</TableCell>
                    <TableCell>{feeDetail.standardPlan}</TableCell>
                    <TableCell>{feeDetail.advancedPlan}</TableCell>
                    <TableCell>{feeDetail.note}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pt-4">
            <Typography variant="body3" color="darkMed" className="pb-1">
              (*) หมายเหตุ อัตราค่าธรรมเนียมอาจมีการเปลี่ยนแปลง ซึ่งบริษัทฯ จะแจ้งให้ท่านทราบต่อไป
            </Typography>
          </div>
        </div>
        <div className="px-5 pt-4">
          <Typography variant="title7" color="primary" className="pt-2 pb-3">
            (2) สถานะการชำระเงินและการถอนเงินจาก deeple pay
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {withdrawHeaders.map((feeHeader) => (
                    <TableCell key={`${feeHeader}`}>{feeHeader}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawDetails.map((withdrawDetail) => (
                  <TableRow key={withdrawDetail.paymentType}>
                    <TableCell component="th" scope="row">
                      {withdrawDetail.paymentType}
                    </TableCell>
                    <TableCell>{withdrawDetail.status}</TableCell>
                    <TableCell>{withdrawDetail.withdrawal}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pt-4">
            <Typography variant="body3" color="darkMed" className="pb-1">
              (*) หมายเหตุ ในกรณีการชำระเงินด้วยบัตรเดบิต / เครดิต ระบบจะสรุปยอดชำระเงินดังกล่าวในเวลา 21.00 น.
              ของทุกวัน โดยรายการสั่งซื้อที่ชำระเงิน (ก) ภายในเวลา 20.00 น. ระบบจะแสดงสถานะการชำระเงินเป็นกำลังประมวลผล
              (Processing) ซึ่งถือว่าการชำระเงินดังกล่าวเสร็จสิ้นแล้ว และระบบจะเปลี่ยนสถานะการชำระเงินเป็นสำเร็จ
              (Completed) ในเวลา 21.00 น. ของวันที่ทำรายการสั่งซื้อดังกล่าว (ข) ตั้งแต่เวลา 20.00 น. เป็นต้นไป
              ระบบจะแสดงสถานะการชำระเงินเป็นกำลังประมวลผล (Processing) และระบบจะเปลี่ยนสถานะการชำระเงินเป็น (Completed)
              ในเวลา 21.00 น. ของวันถัดไป
            </Typography>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ScopeOfService;
