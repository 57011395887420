import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import { size as polishSize } from "polished";
import MUISVGIcon from "@material-ui/core/SvgIcon";

import IcArrowDown from "components/SvgIcons/IcArrowDown";
import COLORS from "constants/Colors";
import { BANKS_CONFIG, BANKS } from "constants/Banks";

type IconArrowWrapperType = {
  isShow: boolean;
  viewBox?: string;
};

type IconSizeType = { [key: string]: string };

type IconWrapperPropsType = {
  size?: "s" | "m" | "l" | "bank";
};

type CircleIconPropsType = {
  color: string;
  size?: "s" | "m";
};

type BankIconPropsType = {
  bankName: BANKS;
  className?: string;
};

const ICON_SIZE: IconSizeType = {
  s: "16px",
  m: "24px",
  l: "32px",
  bank: "20px",
};

export const IconWrapper = styled("img")<IconWrapperPropsType>`
  ${({ size }) => polishSize(ICON_SIZE[size || "s"], ICON_SIZE[size || "s"])}
  text-align: center;
  object-fit: contain;
`;

const BankIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  margin-right: 16px;
`;

export const PromptpayIcon = () => {
  return (
    <BankIconContainer color="white">
      <IconWrapper
        alt="ic-promptpay"
        src="https://storage.googleapis.com/deeple-sme/bank-icon/prompt-pay.png"
        size="bank"
      />
    </BankIconContainer>
  );
};

export const BankIcon = ({ bankName, className }: BankIconPropsType) => {
  // eslint-disable-next-line react/destructuring-assignment
  const bankDetail = BANKS_CONFIG[bankName];
  return bankDetail ? (
    <BankIconContainer color={bankDetail.color} className={className}>
      <IconWrapper
        alt={`ic-${bankDetail.name}`}
        src={`/assets/icon/bank/${bankDetail.name.toLowerCase()}.svg`}
        size="bank"
      />
    </BankIconContainer>
  ) : null;
};

const RotatedIconWrapper = styled.div<IconArrowWrapperType>`
  position: relative;
  top: -7px;
  margin-right: 8px;
  transition: transform 0.3s;
  transform-origin: center;
  transform: rotate(${({ isShow }) => (isShow ? 0 : 90)}deg);
`;

export const AnimatedArrowDownIcon: FC<HTMLAttributes<HTMLDivElement> & IconArrowWrapperType> = ({
  isShow,
  onClick,
  viewBox,
}) => (
  <RotatedIconWrapper isShow={isShow} onClick={onClick}>
    <MUISVGIcon component={IcArrowDown} fontSize="small" htmlColor={COLORS.DarkMed} viewBox={viewBox || "0 0 24 24"} />
  </RotatedIconWrapper>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CircleIcon = styled(({ color, size, ...rest }) => <i {...rest} />).attrs((props: CircleIconPropsType) => {
  const { size: iconSize } = props;
  const size = iconSize === "m" ? "10px" : "6px";
  return { size };
})`
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  background-color: ${({ color }) => color};
  margin-right: ${({ size }) => size};
  display: inline-block;
  vertical-align: middle;
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SvgIcon: any = styled(MUISVGIcon)`
  overflow: visible !important;
  margin-right: 3px;
  padding-right: 6px;
  padding-bottom: 6px;
  &.MuiSvgIcon-fontSizeSmall {
    overflow: visible !important;
    margin-right: 3px;
    padding-right: 6px;
    padding-bottom: 6px;
    font-size: 12px;
  }
  &.MuiSvgIcon-fontSizeLarge {
    overflow: visible !important;
    margin-right: 3px;
    padding-right: 6px;
    padding-bottom: 6px;
    font-size: 16px;
  }
`;
