const billing = {
  en: {
    "Package & Add-on": "Package & Add-on",
    "billing.button.logistic": "Shipping",
    "billing.button.main": "Package & Add-on",
    AddPaymentMethod: "Add payment method",
    PrimaryCard: "Primary card",
    seeMore: "See more",
    seeLess: "See less",
    creditCard: "Credit card",
    "billing.repay.informationModal.error": "Re-paying has been unsuccessful as $t({{errorMessage}})",
    "billing.creditCardList.popup.removeCard": "Remove",
    "billing.creditCardList.confirmModal.removeTitle": "Are you sure you want to remove {{brand}} {{cardNumber}}?",
    "billing.creditCardList.primary": "Primary",
    "billing.invoiceHistory.header.title": "Invoice",
    "billing.invoiceHistory.header.date": "Date",
    "billing.invoiceHistory.header.paymentMethod": "Payment method",
    "billing.invoiceHistory.header.amount": "Amount",
    "billing.invoiceHistory.header.file": "File",
    "billing.invoiceHistory.pdf": "PDF",
    "billing.invoiceHistory.noAttachment": "No attachment",
    "billing.invoiceHistory.paymentMethod.wallet": "deeple credit",
    "billing.invoiceHistory.paymentMethod.qrCode": "QR code",
    "billing.invoiceHistory.paymentMethod.upgradePackageCompensation": "upgrade package compensation",
    "billing.invoiceHistory.paymentMethod.promotion": "promotion",
    "billing.invoiceHistory.promotion.code": "code: {{code}}",
    "billing.invoiceHistory.seeMore": "See more",
    "billing.addCreditCardForm.title": "Add Debit/Credit card",
    "billing.addCreditCardForm.cardNumber": "Card number",
    "billing.addCreditCardForm.validTill": "Valid till",
    "billing.addCreditCardForm.cvv.label": "CVV",
    "billing.addCreditCardForm.cvv.info": "CVV is a 3-digit number on the back of your card.",
    "billing.addCreditCardForm.cardHolder.label": "Cardholder name",
    "billing.addCreditCardForm.cardHolder.placeholder": "Firstname Lastname",
    "billing.addCreditCardForm.description1":
      "Your credit card will be used for monthly renewal payment of package and add-ons on deeple.ai. When saved, it will be charged to make sure it’s valid. That amount will automatically be refunded.",
    "billing.addCreditCardForm.description2": "By adding your debit / credit card, you agree to",
    "billing.addCreditCardForm.termAndCondition": "Terms of service",
    "billing.addCreditCardForm.validDate.info": "You can find it on the front of your card.",
    "billing.addCreditCardForm.error.cardTypeNotSupport": " The credit card type is not supported.",
    "billing.addCreditCardForm.error.cardNumberNotValid": "The card number is invalid.",
    "billing.addCreditCardForm.error.invalidDate": "The expiry date must be a future date",
    "billing.addCreditCardForm.error.submitError": "We’re having issues with your credit cards.",
    "billing.link.upgradePlan": "Upgrade",
    "billing.card.label.main": "Deeple credit",
    "billing.card.label.logistic": "Shipping credit",
    "billing.toast.remove": "{{ brand }} {{ cardNumber }} has been removed.",
    "billing.popConfirm.repay": "Would you like to retry payment with ",
    "billing.repay.button.title": "Retry payment",
    "billing.addCreditCardForm.error.cvvRequired": "CVV number is required",
    "billing.addCreditCardForm.success": "Add credit card success",
    "billing.billingOverdue.overdueInstruction": "Your overdue billing on {{billingDate}} still remains unpaid.",
    "billing.planUpgrade.snackbar.suffix": "Manage Billing",
    "billing.addCreditCardForm.error.required.cardHolder": "Card holder name is required field",

    "billing.info.form.postalCode": "Postal code",
    "billing.info.form.subDistrict": "Sub-district",
    "billing.info.form.district": "District",
    "billing.info.form.province": "Province",
    "billing.info.form.company.button.label": "Corporate",
    "billing.info.form.company.companyName": "Company name",
    "billing.info.form.personal.button.label": "Personal",
    "billing.info.form.personal.IDCard": "ID card",
    "billing.info.form.personal.name": "Full name",
    "billing.info.form.personal.address": "Contact address",

    "BILLING.NON_UNPAID_INVOICE": "You have already completed payment for the current billing.",
    "BILLING.INSUFFICIENT_CREDIT": "Your balance is insufficient. Please contact your issuer bank",
    "BILLING.BILLING_INFO_IS_REQUIRED": "Please add your billing info before proceeding to upgrade your package.",
    "BILLING.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD":
      "Please add your credit card before proceeding to upgrade your package.",
    "BILLING.INVALID_TOTAL_PRICE_VALUE":
      "There's a duplicate transaction. Please contact deeple support team (error:94)",
    "BILLING.UNABLE_TO_REPAY_WITH_CREDIT_CARD":
      "Your credit card was declined. To continue your package, please check and update your payment method",
    "BILLING.DEFAULT_ERROR": "Please try again later",
    "BUY_ADDONS.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD":
      "Your deeple credits remain insufficient . To continue your package, please add a credit card and update your payment method",
    "BUY_ADDONS.INVALID_REMAIN_CREDIT": "Insufficient balance",
    "BUY_ADDONS.NECESSARY_INFO_IS_INSUFFICIENT":
      "Unable to proceed payment, please check your credit card or try again later.",
    APPROVED: "",
    CUSTOMER_CANCELLATION: "Customer cancellation",
    DO_NOT_HONOUR: "The information is invalid. Please contact deeple support team (error:05)",
    DUPLICATE_TRANSMISSION: "There's a duplicate transaction. Please contact deeple support team (error:94)",
    EXPIRED_CARD: "The credit card is expired. Please change your credit card",
    FORMAT_ERROR: "The transaction is invalid. Please contact deeple support team (error:30)",
    INSUFFICIENT_FUNDS: "Your balance is insufficient. Please contact your issuer bank",
    INVALID_AMOUNT: "You have entered invalid amount. Please contact deeple support team (error:13)",
    INVALID_CARD_NUMBER: "The card number is invalid. Please try again ",
    INVALID_MERCHANT_ID: "The information is invalid. Please contact deeple support team (error:03)",
    INVALID_PAYMENT_CONDITION: "Something went wrong. Please try again later.",
    INVALID_TRANSACTION: "The transaction is invalid. Please contact deeple support team (error:12)",
    ISSUER_OR_SWITCH_IS_INOPERATIVE: "Bank service is unavailable. Please contact your issuer bank (error:91)",
    LOST_CARD_PICK_UP: "The card is invalid. Please contact your issuer bank(error:41)",
    RE_ENTER_TRANSACTION: "There is a duplicate transaction. Please contact deeple support team (error:19)",
    REFER_TO_CARD_ISSUER: "Bank service is unavailable. Please contact your issuer bank(error:01)",
    REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON: "Please add your credit card before proceeding to upgrade your add-on.",
    REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN: "Please add your credit card before proceeding to upgrade your package.",
    STOLEN_CARD_PICK_UP: "The card is invalid. Please contact your issuer bank.(error:43)",
    SYSTEM_MALFUNCTION: "Bank service is unavailable. Please contact your issuer bank(error:96)",
    TRANSACTION_NOT_PERMITTED_TO_TERMINAL: "Debit card is not allowed to make the transaction (error:58)",
    TRANSACTION_TIMEOUT: "The transaction has timed out. Please try again later",
    WRONG_CVV: "CVV is incorrect. Please check and try again.",
    "billing.repay.modal.successful": "Your payment has been successful! Please check your confirmation email.",
  },
  th: {
    "Package & Add-on": "แพ็กเกจ & ฟีเจอร์เสริม",
    "billing.button.logistic": "การจัดส่งสินค้า",
    "billing.button.main": "แพ็กเกจ & ฟีเจอร์เสริม",
    AddPaymentMethod: "เพิ่มบัตรเดบิต/เครดิต",
    PrimaryCard: "บัตรหลัก",
    seeMore: "เพิ่มเติม",
    seeLess: "ซ่อน",
    creditCard: "เครดิตการ์ด",
    "billing.repay.informationModal.error": "การชำระเงินอีกครั้งของคุณไม่สำเร็จ เนื่องจาก $t({{errorMessage}})",
    "billing.creditCardList.popup.removeCard": "ลบบัตร",
    "billing.creditCardList.confirmModal.removeTitle":
      "คุณแน่ใจหรือไม่ว่าต้องการถอนการผูกบัตร {{brand}} {{cardNumber}}?",
    "billing.invoiceHistory.header.title": "ใบเสร็จรับเงิน",
    "billing.invoiceHistory.header.date": "วันที่",
    "billing.invoiceHistory.header.paymentMethod": "วิธีการชำระเงิน",
    "billing.invoiceHistory.header.amount": "จำนวน",
    "billing.invoiceHistory.header.file": "ไฟล์",
    "billing.invoiceHistory.pdf": "PDF",
    "billing.invoiceHistory.noAttachment": "ไม่มีไฟล์แนบ",
    "billing.invoiceHistory.paymentMethod.wallet": "deeple credit",
    "billing.invoiceHistory.paymentMethod.qrCode": "QR code",
    "billing.invoiceHistory.paymentMethod.upgradePackageCompensation":
      "เงินชดเชยหลังการหักค่าใช้จ่ายของแพ็กเกจและฟีเจอร์เสริมรายเดือนที่ใช้ไป",
    "billing.invoiceHistory.paymentMethod.promotion": "โปรโมชั่น",
    "billing.invoiceHistory.promotion.code": "รหัส: {{code}}",
    "billing.invoiceHistory.seeMore": "ดูเพิ่มเติม",
    "billing.addCreditCardForm.title": "เพิ่มเดบิต / เครดิตการ์ด",
    "billing.addCreditCardForm.cardNumber": "หมายเลขบัตร",
    "billing.addCreditCardForm.validTill": "วันหมดอายุ",
    "billing.addCreditCardForm.cvv.label": "CVV",
    "billing.addCreditCardForm.cvv.info": "CVV คือตัวเลข 3 หลักจากด้านหลังของบัตรเครดิต",
    "billing.addCreditCardForm.cardHolder.label": "ชื่อผู้ถือบัตร",
    "billing.addCreditCardForm.cardHolder.placeholder": "ชื่อจริง นามสกุล",
    "billing.addCreditCardForm.description1":
      "บัตรเดบิต/เครดิตของคุณจะถูกใช้สำหรับการชำระเงินค่าแพ็กเกจและฟีเจอร์เสริมรายเดือนของ deeple.ai บัตรของคุณจะถูกทดลองเรียกเก็บเงินในขั้นตอนนี้เพื่อให้แน่ใจว่าบัตรสามารถใช้งานได้ หลังจากนั้นระบบจะทำการคืนเงินให้โดยอัตโนมัติ",
    "billing.addCreditCardForm.description2": "การเพิ่มบัตรเดบิต/เครดิตหมายถึงคุณได้ตกลงตาม",
    "billing.addCreditCardForm.termAndCondition": "Terms of service",
    "billing.addCreditCardForm.validDate.info": "คุณสามารถหาวันหมดอายุได้จากหน้าบัตรเครดิต",
    "billing.addCreditCardForm.error.cardTypeNotSupport": "ไม่สามารถใช้บัตรเครดิตประเภทนี้ได้",
    "billing.addCreditCardForm.error.cardNumberNotValid": "เลขบัตรเครดิตไม่ถูกต้อง",
    "billing.addCreditCardForm.error.invalidDate": "วันหมดอายุต้องเป็นวันในอนาคต",
    "billing.addCreditCardForm.error.submitError": "พบปัญหาเกี่ยวกับบัตรเครดิตของคุณ กรุณาลองใหม่อีกครั้ง",
    "billing.creditCardList.primary": "บัตรหลัก",
    "billing.link.upgradePlan": "เปลี่ยนแพ็กเกจ",
    "billing.card.label.main": "Deeple credit",
    "billing.card.label.logistic": "Shipping credit",
    "billing.toast.remove": "บัตรเดรดิต {{ brand }} {{ cardNumber }} ถูกลบออกจากระบบ",
    "billing.popConfirm.repay": "คุณต้องการชำระเงินอีกครั้งด้วย ",
    "billing.repay.button.title": "ชำระเงิน",
    "billing.addCreditCardForm.error.cvvRequired": "กรุณาระบุหมายเลข CVV",
    "billing.addCreditCardForm.success": "เพิ่มบัตรเครดิตสำเร็จ",
    "billing.billingOverdue.overdueInstruction": "คุณยังคงมียอดค้างชำระสำหรับการเรียกเก็บเงินวันที่ {{billingDate}}",
    "billing.planUpgrade.snackbar.suffix": "จัดการการเรียกเก็บเงิน",
    "billing.addCreditCardForm.error.required.cardHolder": "กรุณาระบุชื่อที่อยู่บนบัตร",

    "billing.info.form.postalCode": "รหัสไปรษณีย์",
    "billing.info.form.subDistrict": "แขวง/ตำบล",
    "billing.info.form.district": "เขต/อำเภอ",
    "billing.info.form.province": "จังหวัด",
    "billing.info.form.company.button.label": "ข้อมูลนิติบุคคล",
    "billing.info.form.company.companyName": "ชื่อบริษัท",
    "billing.info.form.personal.button.label": "ข้อมูลส่วนบุคคล",
    "billing.info.form.personal.IDCard": "บัตรประชาชน",
    "billing.info.form.personal.name": "ชื่อ-นามสกุล",
    "billing.info.form.personal.address": "ที่อยู่",

    "BILLING.NON_UNPAID_INVOICE": "คุณได้ทำการชำระเงินสำหรับการเรียกเก็บเงินในปัจจุบันแล้ว",
    "BILLING.INSUFFICIENT_CREDIT": "จำนวนเงินไม่เพียงพอ โปรดติดต่อธนาคารผู้ออกบัตร",
    "BILLING.BILLING_INFO_IS_REQUIRED": "โปรดเพิ่มข้อมูลการเรียกเก็บเงินก่อนที่จะดำเนินการอัพเกรดแพ็คเกจของคุณ",
    "BILLING.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD": "โปรดเพิ่มบัตรเครดิตก่อนที่จะดำเนินการอัพเกรดแพ็คเกจของคุณ",
    "BILLING.INVALID_TOTAL_PRICE_VALUE": "มีธุรกรรมที่ซ้ำกัน โปรดติดต่อทีมสนับสนุนของ deeple (ข้อผิดพลาด:94)",
    "BILLING.UNABLE_TO_REPAY_WITH_CREDIT_CARD":
      "บัตรเครดิตของคุณถูกปฏิเสธ. หากต้องการดำเนินการต่อ โปรดตรวจสอบและอัปเดตวิธีการชำระเงินของคุณ",
    "BILLING.DEFAULT_ERROR": "กรุณาลองใหม่อีกครั้ง",
    "BUY_ADDONS.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD":
      "เครดิตของคุณยังไม่เพียงพอ หากต้องการทำแพ็กเกจต่อ โปรดเพิ่มบัตรเครดิตและอัปเดตวิธีการชำระเงินของคุณ",
    "BUY_ADDONS.INVALID_REMAIN_CREDIT": "วงเงินคงเหลือไม่เพียงพอ",
    "BUY_ADDONS.NECESSARY_INFO_IS_INSUFFICIENT":
      "ไม่สามารถดำเนินการชำระเงินได้ โปรดตรวจสอบบัตรเครดิตของคุณหรือลองอีกครั้งในภายหลัง",
    APPROVED: "",
    CUSTOMER_CANCELLATION: "Customer cancellation",
    DO_NOT_HONOUR: "ข้อมูลไม่ถูกต้อง กรุณาติดต่อทีมสนับสนุนของ deeple (error:05)",
    DUPLICATE_TRANSMISSION: "มีการทำรายการซ้ำซ้อน กรุณาติดต่อทีมสนับสนุนของ deeple (error:94)",
    EXPIRED_CARD: "บัตรเครดิตหมดอายุ กรุณาเปลี่ยนบัตรเครดิต",
    FORMAT_ERROR: "การทำรายการไม่ถูกต้อง กรุณาติดต่อทีมสนับสนุนของ deeple (error:30)",
    INSUFFICIENT_FUNDS: "จำนวนเงินไม่เพียงพอ โปรดติดต่อธนาคารผู้ออกบัตร",
    INVALID_AMOUNT: "จำนวนเงินไม่ถูกต้อง กรุณาติดต่อทีมสนับสนุนของ deeple (error:13)",
    INVALID_CARD_NUMBER: "รหัสบัตรเครดิตไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
    INVALID_MERCHANT_ID: "ข้อมูลไม่ถูกต้อง กรุณาติดต่อทีมสนับสนุนของ deeple (error:03)",
    INVALID_PAYMENT_CONDITION: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง",
    INVALID_TRANSACTION: "การทำรายการไม่ถูกต้อง กรุณาติดต่อทีมสนับสนุนของ deeple (error:12)",
    ISSUER_OR_SWITCH_IS_INOPERATIVE: "ระบบธนาคารไม่สามารถใช้งานได้ โปรดติดต่อธนาคารผู้ออกบัตร (error:91)",
    LOST_CARD_PICK_UP: "บัตรเครดิตไม่ถูกต้อง โปรดติดต่อธนาคารผู้ออกบัตร (error:41)",
    RE_ENTER_TRANSACTION: "มีการทำรายการซ้ำซ้อน กรุณาติดต่อทีมสนับสนุนของ deeple (error:19)",
    REFER_TO_CARD_ISSUER: "ระบบธนาคารไม่สามารถใช้งานได้ โปรดติดต่อธนาคารผู้ออกบัตร (error:01)",
    REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON: "กรุณาผูกบัตรเครดิตก่อนจะทำการเปลี่ยนฟีเจอร์เสริม",
    REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN: "กรุณาผูกบัตรเครดิตก่อนจะทำการเปลี่ยนแพ็คเกจ",
    STOLEN_CARD_PICK_UP: "บัตรเครดิตไม่ถูกต้อง โปรดติดต่อธนาคารผู้ออกบัตร (error:43)",
    SYSTEM_MALFUNCTION: "ระบบธนาคารไม่สามารถใช้งานได้ โปรดติดต่อธนาคารผู้ออกบัตร (error:96)",
    TRANSACTION_NOT_PERMITTED_TO_TERMINAL: "ผู้ออกบัตรไม่อนุญาตให้ทำรายการด้วยบัตรเดบิต (error:58)",
    TRANSACTION_TIMEOUT: "หมดเวลาการทำธุรกรรม กรุณาลองใหม่อีกครั้ง",
    WRONG_CVV: "รหัส CVV ไม่ถูกต้อง กรุณาตรวจสอบและลองใหม่อีกครั้ง",
    "billing.repay.modal.successful": "การชำระเงินของคุณสำเร็จแล้ว! โปรดตรวจสอบอีเมลยืนยันของคุณ",
  },
};

export default billing;
