import React, { useState, useEffect } from "react";
import moment from "moment";
import get from "lodash/get";
import { Components } from "@deeple-ai/web-console";

import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { MESSENGER_PREVIEW_IMAGE_MOBILE, MESSENGER_PREVIEW_IMAGE_DESKTOP } from "constants/Facebook";

import Button from "components/Button";
import Grid from "components/Grid";
import Card from "components/Card";
import Divider from "components/Divider";
import Typography from "components/Typography";
import ConfirmationModal from "components/ConfirmationModal";
import CircularProgress from "components/CircularProgress";
import { IcSave, IcDelete, IcArrowBack } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { MARKETING_TOOLS, FACEBOOK_LIVE, PROJECT, AI_SETTINGS } from "constants/Router";
import { ProjectIdType } from "types/Project";
import { FacebookLiveInput, FacebookLive, LiveStatusType } from "types/FacebookLive";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import useGetProject from "utils/hooks/useGetProject";
import { removeTypenameField } from "utils/common";

import { PROJECT_SETTING } from "graphql/setting/query";
import { ProjectSettingQueryType } from "types/Setting";
import { useQuery } from "@apollo/client";
import ButtonBase from "components/ButtonBase";
import FacebookLiveAddEditForm from "../FacebookLiveAddEditForm.tsx";
import FacebookLiveStat from "./FacebookLiveStat";
import useGetFacebookCrawler from "./hooks/useGetFacebookCrawler";
import useCreateLiveCrawler from "./hooks/useCreateLiveCrawler";
import useUpdateFacebookCrawler from "./hooks/useUpdateFacebookCrawler";
import useDeleteLiveCrawler from "./hooks/useDeleteLiveCrawler";

function FacebookLiveFormContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, postId: liveCrawlerFormId } = useParams<ProjectIdType & { postId: string }>();
  const { facebookPageId } = useGetProject(projectId);
  const device = useDevice();
  const isMobile = Device.MOBILE === device;
  const { data: projectSettingData, refetch: refetchProjectSetting } = useQuery<ProjectSettingQueryType>(
    PROJECT_SETTING,
    {
      variables: {
        projectId,
      },
    },
  );

  const [isSubmittingSaveDraft, setIsSubmittingSaveDraft] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [facebookCrawlerData, setFacebookCrawlerData] = useState<FacebookLiveInput>();
  const [isShowAdminModeWarningPopup, setIsShowAdminModeWarningPopup] = useState(false);

  const closeFormAndBackToMainPage = () => {
    history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${FACEBOOK_LIVE}`);
  };

  const { liveCrawlerGQLData, isLiveCrawlerLoading } = useGetFacebookCrawler(projectId, liveCrawlerFormId || "");

  const { createLiveCrawler, createLiveCrawlerLoading } = useCreateLiveCrawler(
    setIsSubmittingSaveDraft,
    closeFormAndBackToMainPage,
  );
  const { updateLiveCrawler, updateLiveCrawlerLoading } = useUpdateFacebookCrawler(
    setIsSubmittingSaveDraft,
    closeFormAndBackToMainPage,
  );
  const { deleteLiveCrawler, deleteLiveCrawlerLoading } = useDeleteLiveCrawler(
    setIsOpenConfirmDeleteModal,
    closeFormAndBackToMainPage,
  );

  const submitDeleteLiveCrawler = () => {
    if (deleteLiveCrawlerLoading) {
      return;
    }

    if (liveCrawlerFormId) {
      deleteLiveCrawler({
        variables: {
          projectId,
          liveCrawlerId: liveCrawlerFormId,
        },
      });
    } else {
      setIsOpenConfirmDeleteModal(false);
    }
  };

  const submitSaveDraft = async () => {
    if (createLiveCrawlerLoading) {
      return;
    }

    if (updateLiveCrawlerLoading) {
      return;
    }

    await refetchProjectSetting({
      variables: {
        projectId,
      },
    });

    const getProject = projectSettingData?.getProject;
    if (getProject) {
      const { isChatbotEnabled } = getProject;
      const { isProcessingPostbackEnabled } = getProject.configuration.botFlowBehavior.postback;

      const isAdminModeEnabledWithProcessingPostbackDisabled = !isChatbotEnabled && !isProcessingPostbackEnabled;

      if (isAdminModeEnabledWithProcessingPostbackDisabled) {
        setIsShowAdminModeWarningPopup(true);
        return;
      }
    }

    if (liveCrawlerFormId !== "new") {
      setIsSubmittingSaveDraft(true);
      updateLiveCrawler({
        variables: {
          projectId,
          liveCrawlerId: liveCrawlerFormId,
          liveCrawler: {
            isActive: facebookCrawlerData?.isActive,
            isIncludedAllProducts: facebookCrawlerData?.isIncludedAllProducts,
            postId: facebookCrawlerData?.postId,
            productSKUIds: facebookCrawlerData?.productSKUIds,
            title: facebookCrawlerData?.title,
            videoId: facebookCrawlerData?.videoId,
          },
        },
      });
    } else {
      setIsSubmittingSaveDraft(true);

      createLiveCrawler({
        variables: {
          projectId,
          liveCrawler: {
            isActive: facebookCrawlerData?.isActive,
            isIncludedAllProducts: facebookCrawlerData?.isIncludedAllProducts,
            postId: facebookCrawlerData?.postId,
            productSKUIds: facebookCrawlerData?.productSKUIds,
            title: facebookCrawlerData?.title,
            videoId: facebookCrawlerData?.videoId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (liveCrawlerFormId === "new") {
      // Create new live crawler
      const initFacebookCrawlerData: FacebookLiveInput = {
        title: "",
        isActive: true,
        postId: "",
        videoId: "",
        productSKUIds: [],
        isIncludedAllProducts: false,
      };
      setFacebookCrawlerData(initFacebookCrawlerData);
    } else if (liveCrawlerGQLData) {
      // Polish data from graphQL
      const newLiveCrawlerGQLData = removeTypenameField(
        liveCrawlerGQLData && liveCrawlerGQLData.liveCrawler,
      ) as FacebookLive;

      const liveCrawlerProductSKUIds = (newLiveCrawlerGQLData?.productSKUs || [])
        .map(({ id }) => id)
        .filter((val) => val);

      setFacebookCrawlerData({
        postId: newLiveCrawlerGQLData.postId,
        title: newLiveCrawlerGQLData.title,
        isActive: newLiveCrawlerGQLData.isActive,
        videoId: newLiveCrawlerGQLData.videoId,
        liveStatus: newLiveCrawlerGQLData.liveStatus,
        productSKUIds: liveCrawlerProductSKUIds,
        isIncludedAllProducts: newLiveCrawlerGQLData.isIncludedAllProducts,
      });
    }
  }, [liveCrawlerFormId, liveCrawlerGQLData]);

  const isValidToSave =
    facebookCrawlerData &&
    Boolean(facebookCrawlerData.title) &&
    Boolean(facebookCrawlerData.postId) &&
    (Boolean(facebookCrawlerData.productSKUIds.length) || facebookCrawlerData?.isIncludedAllProducts);

  const isEdit = liveCrawlerFormId !== "new";
  const isLiveStatusEnded = facebookCrawlerData?.liveStatus === LiveStatusType.END || false;
  const imagePreview = isMobile ? MESSENGER_PREVIEW_IMAGE_MOBILE : MESSENGER_PREVIEW_IMAGE_DESKTOP;

  const renderFacebookLiveStat = () => (
    <FacebookLiveStat
      numberOfReach={get(liveCrawlerGQLData, "liveCrawler.numberOfReach") || 0}
      numberOfEngagement={get(liveCrawlerGQLData, "liveCrawler.numberOfEngagement") || 0}
      orderPaidConversionRate={get(liveCrawlerGQLData, "liveCrawler.orderPaidConversionRate") || 0}
      orderPaidConversionTotal={get(liveCrawlerGQLData, "liveCrawler.orderPaidConversionTotal") || 0}
      createdAt={moment(get(liveCrawlerGQLData, "liveCrawler.createdAt")).format(BROADCAST_DATE_TIME_FORMAT)}
      updatedAt={moment(get(liveCrawlerGQLData, "liveCrawler.updatedAt")).format(BROADCAST_DATE_TIME_FORMAT)}
    />
  );

  const handleNavigateToAiSettingPage = () => {
    history.push(`/${PROJECT}/${projectId}/${AI_SETTINGS}`);
  };

  const handleCloseAdminModeWarningPopup = () => {
    setIsShowAdminModeWarningPopup(false);
  };

  return (
    <Grid id="FacebookFormContainer" container alignContent="flex-start">
      <div className="web-console">
        <Components.AdminModeWarningPopup
          isOpen={isShowAdminModeWarningPopup}
          onClose={handleCloseAdminModeWarningPopup}
          onSubmit={handleNavigateToAiSettingPage}
        />
      </div>

      <Grid item container xs={12}>
        <Grid item xs={12}>
          <ButtonBase
            color="secondary"
            className="ml-3 mt-4 p-2"
            onClick={() => {
              history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${FACEBOOK_LIVE}`);
            }}
          >
            <SvgIcon component={IcArrowBack} fontSize="small" />
            {t("Back")}
          </ButtonBase>
        </Grid>

        <Grid item xs={12} className="px-3">
          <Divider margin="22px 0 0 0" />
        </Grid>
      </Grid>

      <Grid item container xs={12} alignItems="center" alignContent="center" className="py-3 px-3">
        <Grid item xs={12} md={6}>
          <Typography variant="title1" color="dark">
            {t(isEdit ? "facebookLiveForm.header.edit" : "facebookLiveForm.header")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          justify="flex-end"
          alignItems="center"
          alignContent="center"
          className={isMobile ? "pt-2" : ""}
        >
          {isEdit && (
            <Button
              color="secondary"
              className="mx-1"
              size="medium"
              onClick={() => {
                setIsOpenConfirmDeleteModal(true);
              }}
            >
              <Grid container alignItems="center" alignContent="center" className="mx-2">
                <SvgIcon component={IcDelete} fontSize="small" className="mr-2" />
                {t("Delete")}
              </Grid>
            </Button>
          )}
          <Button
            color="primary"
            className="mx-1"
            size="medium"
            onClick={submitSaveDraft}
            disabled={!isValidToSave || isSubmittingSaveDraft || isLiveStatusEnded}
          >
            <Grid container alignItems="center" alignContent="center" className="mx-2">
              <SvgIcon component={IcSave} fontSize="small" className="mr-2" />
              {t("Save")}
            </Grid>
          </Button>
        </Grid>
      </Grid>

      {/* Title panel */}

      {isLiveCrawlerLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      ) : (
        <>
          {facebookCrawlerData && (
            <>
              {isMobile && isEdit && (
                <Grid container item xs={12} className="px-3">
                  {renderFacebookLiveStat()}
                </Grid>
              )}

              <Grid container item xs={12} md={6} className="p-3 pt-0">
                <FacebookLiveAddEditForm
                  isNewCreateForm={!isEdit}
                  projectId={projectId}
                  setFacebookCrawlerData={setFacebookCrawlerData}
                  disabledForm={isEdit}
                  facebookPageId={facebookPageId || ""}
                  facebookCrawlerData={facebookCrawlerData}
                  facebookLiveSelectedProductSKUs={liveCrawlerGQLData?.liveCrawler?.productSKUs}
                  onClickAlert={handleNavigateToAiSettingPage}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                className="p-3 pt-0"
                alignContent="flex-start"
                alignItems="flex-start"
              >
                {!isMobile && isEdit && renderFacebookLiveStat()}

                <Card borderRadius={8} padding="8px 0" fullWidth>
                  <Grid container justify="center">
                    <img src={imagePreview} alt="messenger-preview" />
                  </Grid>
                </Card>
              </Grid>
            </>
          )}
        </>
      )}

      {/* Confirm delete modal */}
      <ConfirmationModal
        title={`${t("facebookLiveForm.confirmDelete")} ${facebookCrawlerData?.title || ""}?`}
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onSubmit={() => {
          submitDeleteLiveCrawler();
        }}
      />
    </Grid>
  );
}

export default FacebookLiveFormContainer;
