import { useQuery } from "@apollo/client";
import React, { FC, lazy, Suspense, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { NotFound } from "pages/NotFound";
import { Impersonate } from "pages/Impersonate";
import { Project } from "pages/Project";
import ErrorPage from "components/Error";

import {
  IMPERSONATE,
  PROJECT,
  // ORDERS,
  FORGOT_PASSWORD,
  REGISTER,
  REGISTER_WITH_EMAIL,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  TERM_AND_CONDITION,
} from "constants/Router";

import { FCMTokenHandler } from "services/FirebaseService";
import { UserProfileDetailType, UserProfileType } from "types/User";
import PrivacyPolicy from "domain/PrivacyPolicy";
import Congratulations from "domain/Congratulations";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { ErrorContext } from "utils/context";
import useToggle from "utils/hooks/useToggle";
import PrivateRoute from "./PrivateRoute";
import AppRoute from "./AppRoute";

import LoginRoute from "./LoginRoute";

const NewConfirmedEmail = lazy(() => import("pages/NewConfirmedEmail"));
const NewForgotPassword = lazy(() => import("pages/NewForgotPassword"));
const NewLogin = lazy(() => import("pages/NewLogIn"));
const NewRegister = lazy(() => import("pages/NewRegister"));
const NewResetPassword = lazy(() => import("pages/NewResetPassword"));
const RegisterWithEmail = lazy(() => import("pages/RegisterWithEmail"));
const TermAndCondition = lazy(() => import("pages/TermAndCondition"));
const NewVerifyEmail = lazy(() => import("pages/NewVerifyEmail"));

const AppRouter: FC = () => {
  const {
    isOpen: isOpenCongratulationsModal,
    handleToggle: handleToggleCongratulationsModal,
    handleClose: handleCloseCongratulationsModal,
  } = useToggle();

  const { errorCode } = useContext(ErrorContext);
  const { data, loading } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE);
  const hasSingleProject = data?.me?.permissions?.length === 1;
  const isEmailVerified = Boolean(data?.me?.isEmailVerified);
  const currentUser = data?.me;
  const isLoggedin = Boolean(currentUser);
  const isAccepted = Boolean(data?.me?.isAccepted);
  const hasNoProject = Boolean(data?.me?.permissions?.length === 0);
  const hasNextToProject = hasSingleProject && isAccepted;
  const isShowModalCongratulations = isOpenCongratulationsModal && hasNoProject;
  const isShowModalPrivacyPolicy = !isAccepted && isLoggedin && isEmailVerified;

  if (errorCode) {
    return (
      <ErrorPage errorCode={errorCode} buttonText="Try again" handleClickButton={() => window.location.reload()} />
    );
  }

  return (
    <>
      <Router>
        <FCMTokenHandler loading={loading} currentUser={currentUser as UserProfileDetailType} />
        <Congratulations isOpen={isShowModalCongratulations} onSubmit={handleCloseCongratulationsModal} />

        <PrivacyPolicy isOpen={isShowModalPrivacyPolicy} onSubmit={handleToggleCongratulationsModal} />

        <Suspense fallback={<div />}>
          <Switch>
            <PrivateRoute
              exact
              loading={loading}
              path={`/${PROJECT}`}
              isAllowed={isEmailVerified}
              redirectTo="/"
              component={() => (
                <Project
                  isShowModalCongratulations={isShowModalCongratulations}
                  isShowModalPrivacyPolicy={isShowModalPrivacyPolicy}
                />
              )}
              title="Store"
            />

            <Route exact path={`/${IMPERSONATE}`}>
              <Impersonate>
                <NotFound />
              </Impersonate>
            </Route>

            <Route path={`/${PROJECT}/:projectId`}>
              <AppRoute />
            </Route>

            <LoginRoute
              exact
              path="/"
              isEmailVerified={isEmailVerified}
              isLoggedin={isLoggedin}
              redirectTo={`/${VERIFY_EMAIL}`}
              nextTo={hasNextToProject ? `/${PROJECT}/${data?.me?.permissions[0].projectId}` : `/${PROJECT}`}
              component={NewLogin}
            />
            <PrivateRoute
              exact
              loading={loading}
              path={`/${REGISTER_WITH_EMAIL}`}
              isAllowed={!isLoggedin}
              component={RegisterWithEmail}
              redirectTo="/"
              title="register.header"
            />
            <PrivateRoute
              exact
              loading={loading}
              path={`/${VERIFY_EMAIL}`}
              isAllowed={isLoggedin}
              redirectTo="/"
              component={NewVerifyEmail}
              title="Verify email"
            />
            <Route exact path={`/${FORGOT_PASSWORD}`} component={NewForgotPassword} />
            <Route exact path={`/${RESET_PASSWORD}`} component={NewResetPassword} />
            <Route exact path={`/${TERM_AND_CONDITION}`} component={TermAndCondition} />
            <Route exact path="/email/verify" component={NewConfirmedEmail} />
            <Route path="/auth/redirect" component={() => <>Loading...</>} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export { AppRouter };
